import axios from "axios";
import {
    DELETE_WORDS_REMEMBER_FAILURE,
    DELETE_WORDS_REMEMBER_REQUEST,
    DELETE_WORDS_REMEMBER_SUCCESS,
    INSERT_WORDS_REMEMBER_FAILURE,
    INSERT_WORDS_REMEMBER_REQUEST,
    INSERT_WORDS_REMEMBER_SUCCESS,
    LOAD_WORDS_REMEMBER_FAILURE, LOAD_WORDS_REMEMBER_REQUEST,
    LOAD_WORDS_REMEMBER_SUCCESS,
    LOAD_WORDS_REMEMBERS_FAILURE,
    LOAD_WORDS_REMEMBERS_REQUEST,
    LOAD_WORDS_REMEMBERS_SUCCESS,
    UPDATE_WORDS_REMEMBER_FAILURE, UPDATE_WORDS_REMEMBER_REQUEST, UPDATE_WORDS_REMEMBER_SUCCESS
} from "./constants";
import {all, fork, takeLatest, call, put} from 'redux-saga/effects';

function loadWordsRemembersAPI() {
    return axios.get('/vendor/api/v1/wordsRemember')
}

function loadWordsRememberAPI(data) {
    return axios.get(`/vendor/api/v1/wordsRemember/${data.seq}`)
}

function insertWordsRememberAPI(data) {
    return axios.post('/vendor/api/v1/wordsRemember', data)
}

function updateWordsRememberAPI(data) {
    return axios.put('/vendor/api/v1/wordsRemember', data);
}

function deleteWordsRememberAPI(data) {
    return axios.delete('/vendor/api/v1/wordsRemember', {data})
}

function* loadWordsRemembers(action) {
    try {
        const result = yield call(loadWordsRemembersAPI, action.data);
        yield put({
            type: LOAD_WORDS_REMEMBERS_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: LOAD_WORDS_REMEMBERS_FAILURE,
            data: err.response.data,
        });
    }
}

function* loadWordsRemember(action) {
    try {
        const result = yield call(loadWordsRememberAPI, action.data);
        yield put({
            type: LOAD_WORDS_REMEMBER_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: LOAD_WORDS_REMEMBER_FAILURE,
            data: err.response.data,
        });
    }
}

function* insertWordsRemember(action) {
    try {
        const result = yield call(insertWordsRememberAPI, action.data);
        yield put({
            type: INSERT_WORDS_REMEMBER_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: INSERT_WORDS_REMEMBER_FAILURE,
            data: err.response.data,
        });
        alert(err.response.data.data.message);
    }
}

function* updateWordsRemember(action) {
    try {
        const result = yield call(updateWordsRememberAPI, action.data);
        yield put({
            type: UPDATE_WORDS_REMEMBER_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: UPDATE_WORDS_REMEMBER_FAILURE,
            data: err.response.data,
        });
    }
}

function* deleteWordsRemember(action) {
    try {
        const result = yield call(deleteWordsRememberAPI, action.data);
        yield put({
            type: DELETE_WORDS_REMEMBER_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: DELETE_WORDS_REMEMBER_FAILURE,
            data: err.response.data,
        });
    }
}

function* watchLoadWordsRemembers() {
    yield takeLatest(LOAD_WORDS_REMEMBERS_REQUEST, loadWordsRemembers);
}

function* watchLoadWordsRemember() {
    yield takeLatest(LOAD_WORDS_REMEMBER_REQUEST, loadWordsRemember);
}

function* watchInsertWordsRemember() {
    yield takeLatest(INSERT_WORDS_REMEMBER_REQUEST, insertWordsRemember);
}

function* watchUpdateWordsRemember() {
    yield takeLatest(UPDATE_WORDS_REMEMBER_REQUEST, updateWordsRemember);
}

function* watchDeleteWordsRemember() {
    yield takeLatest(DELETE_WORDS_REMEMBER_REQUEST, deleteWordsRemember);
}

export default function* saga() {
    yield all([
        fork(watchLoadWordsRemembers),
        fork(watchLoadWordsRemember),
        fork(watchInsertWordsRemember),
        fork(watchUpdateWordsRemember),
        fork(watchDeleteWordsRemember),
    ])
}

