import {
    DELETE_QUESTION_FAILURE,
    DELETE_QUESTION_REQUEST,
    DELETE_QUESTION_SUCCESS,
    INSERT_QUESTION_FAILURE,
    INSERT_QUESTION_REQUEST,
    INSERT_QUESTION_SUCCESS,
    LOAD_QUESTIONS_FAILURE,
    LOAD_QUESTIONS_REQUEST,
    LOAD_QUESTIONS_SUCCESS,
    TRANS_QUESTION_FAILURE,
    TRANS_QUESTION_REQUEST,
    TRANS_QUESTION_SUCCESS,
    UPDATE_QUESTION_FAILURE,
    UPDATE_QUESTION_REQUEST,
    UPDATE_QUESTION_SUCCESS
} from "./constants";
import produce from "immer";

const initialState = {
    questions: {},
    questionTranslate: '',
    count: 0,
    loadQuestionsLoading: false,
    loadQuestionsDone: false,
    loadQuestionsError: false,
    insertQuestionLoading: false,
    insertQuestionDone: false,
    insertQuestionError: null,
    deleteQuestionLoading: false,
    deleteQuestionDone: false,
    deleteQuestionError: false,
    transQuestionLoading: false,
    transQuestionDone: false,
    transQuestionError: false,
}

export const loadQuestionsRequestAction = (data) => {
    return {
        type: LOAD_QUESTIONS_REQUEST,
        data,
    }
}

export const insertQuestionRequestAction = (data) => {
    return {
        type: INSERT_QUESTION_REQUEST,
        data
    }
}

export const updateQuestionRequestAction = (data) => {
    return {
        type: UPDATE_QUESTION_REQUEST,
        data,
    }
}

export const deleteQuestionRequestAction = (data) => {
    return {
        type: DELETE_QUESTION_REQUEST,
        data
    }
}

export const transQuestionRequestAction = (data) => {
    return {
        type: TRANS_QUESTION_REQUEST,
        data
    }
}

const reducer = (state = initialState, action) => produce(state, (draft) => {
    switch (action.type) {
        case LOAD_QUESTIONS_REQUEST:
            draft.loadQuestionsLoading = true;
            draft.loadQuestionsDone = false;
            draft.loadQuestionsError = null;
            break;
        case LOAD_QUESTIONS_SUCCESS:
            draft.loadQuestionsLoading = false;
            draft.loadQuestionsDone = true;
            draft.questions = action.data.data;
            draft.count = action.data.count[0].count;
            break;
        case LOAD_QUESTIONS_FAILURE:
            draft.loadQuestionsLoading = false;
            draft.loadQuestionsDone = false;
            draft.loadQuestionsError = action.data;
            break;
        case INSERT_QUESTION_REQUEST:
            draft.insertQuestionLoading = true;
            draft.insertQuestionDone = false;
            draft.insertQuestionError = null;
            break;
        case INSERT_QUESTION_SUCCESS:
            draft.insertQuestionLoading = false;
            draft.insertQuestionDone = true;
            break;
        case INSERT_QUESTION_FAILURE:
            draft.insertQuestionLoading = false;
            draft.insertQuestionDone = false;
            draft.insertQuestionError = action.data;
            break;
        case UPDATE_QUESTION_REQUEST:
            draft.updateQuestionLoading = true;
            draft.updateQuestionDone = false;
            draft.updateQuestionError = null;
            break;
        case UPDATE_QUESTION_SUCCESS:
            draft.updateQuestionLoading = false;
            draft.updateQuestionDone = true;
            break;
        case UPDATE_QUESTION_FAILURE:
            draft.updateQuestionLoading = false;
            draft.updateQuestionDone = false;
            draft.updateQuestionError = action.data;
            break;
        case DELETE_QUESTION_REQUEST:
            draft.deleteQuestionLoading = true;
            draft.deleteQuestionDone = false;
            draft.deleteQuestionError = null;
            break;
        case DELETE_QUESTION_SUCCESS:
            draft.deleteQuestionLoading = false;
            draft.deleteQuestionDone = true;
            draft.questions = draft.questions.filter(i => i.seq !== action.data.seq);
            break;
        case DELETE_QUESTION_FAILURE:
            draft.deleteQuestionLoading = false;
            draft.deleteQuestionDone = false;
            draft.deleteQuestionError = action.data;
            break;
        case TRANS_QUESTION_REQUEST:
            draft.transQuestionLoading = true;
            draft.transQuestionDone = false;
            draft.transQuestionError = null;
            break;
        case TRANS_QUESTION_SUCCESS:
            draft.transQuestionLoading = false;
            draft.transQuestionDone = true;
            draft.questionTranslate = action.data;
            break;
        case TRANS_QUESTION_FAILURE:
            draft.transQuestionLoading = false;
            draft.transQuestionDone = false;
            draft.transQuestionError = action.data;
            break;
    }
});

export default reducer;

