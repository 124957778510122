import history from "./history";
import qs from "qs";

const getQueryString = () => {
    try {
        const rawQueryString = decodeURI(history.location.search);
        const parsedQueryString = qs.parse(rawQueryString, {
            ignoreQueryPrefix: true,
        });

        Object.keys(parsedQueryString).forEach((key) => {
            if (parsedQueryString[key] === "true") {
                parsedQueryString[key] = true;
            } else if (parsedQueryString[key] === "false") {
                parsedQueryString[key] = false;
            }
        });

        return parsedQueryString;
    } catch (e) {
        console.error(e);
        return {};
    }
}

export default getQueryString;
