export const LOAD_WORDS_REMEMBERS_REQUEST = 'LOAD_WORDS_REMEMBERS_REQUEST';
export const LOAD_WORDS_REMEMBERS_SUCCESS = 'LOAD_WORDS_REMEMBERS_SUCCESS';
export const LOAD_WORDS_REMEMBERS_FAILURE = 'LOAD_WORDS_REMEMBERS_FAILURE';

export const LOAD_WORDS_REMEMBER_REQUEST = 'LOAD_WORDS_REMEMBER_REQUEST';
export const LOAD_WORDS_REMEMBER_SUCCESS = 'LOAD_WORDS_REMEMBER_SUCCESS';
export const LOAD_WORDS_REMEMBER_FAILURE = 'LOAD_WORDS_REMEMBER_FAILURE';

export const INSERT_WORDS_REMEMBER_REQUEST = 'INSERT_WORDS_REMEMBER_REQUEST';
export const INSERT_WORDS_REMEMBER_SUCCESS = 'INSERT_WORDS_REMEMBER_SUCCESS';
export const INSERT_WORDS_REMEMBER_FAILURE = 'INSERT_WORDS_REMEMBER_FAILURE';

export const UPDATE_WORDS_REMEMBER_REQUEST = 'UPDATE_WORDS_REMEMBER_REQUEST';
export const UPDATE_WORDS_REMEMBER_SUCCESS = 'UPDATE_WORDS_REMEMBER_SUCCESS';
export const UPDATE_WORDS_REMEMBER_FAILURE = 'UPDATE_WORDS_REMEMBER_FAILURE';

export const DELETE_WORDS_REMEMBER_REQUEST = 'DELETE_WORDS_REMEMBER_REQUEST';
export const DELETE_WORDS_REMEMBER_SUCCESS = 'DELETE_WORDS_REMEMBER_SUCCESS';
export const DELETE_WORDS_REMEMBER_FAILURE = 'DELETE_WORDS_REMEMBER_FAILURE';
