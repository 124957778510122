import LayoutTemplate from "../../components/templates/layout";
import IntroduceTemplate from "../../components/templates/introduce";

const Introduce = () => {
    return (
        <LayoutTemplate>
            <IntroduceTemplate />
        </LayoutTemplate>
    )
}

export default Introduce;
