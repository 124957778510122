import {
    DELETE_BOARD_FAILURE,
    DELETE_BOARD_REQUEST,
    DELETE_BOARD_SUCCESS,
    INSERT_BOARD_FAILURE,
    INSERT_BOARD_REQUEST,
    INSERT_BOARD_SUCCESS, LOAD_BOARD_FAILURE,
    LOAD_BOARD_REQUEST, LOAD_BOARD_SUCCESS,
    LOAD_BOARDS_FAILURE,
    LOAD_BOARDS_REQUEST,
    LOAD_BOARDS_SUCCESS,
    UPDATE_BOARD_FAILURE, UPDATE_BOARD_REQUEST, UPDATE_BOARD_SUCCESS
} from "./constants";
import produce from "immer";

const initialState = {
    board: {},
    boards: [],
    loadBoardsLoading: false,
    loadBoardsDone: false,
    loadBoardsError: false,
    loadBoardLoading: false,
    loadBoardDone: false,
    loadBoardError: false,
    insertBoardLoading: false,
    insertBoardDone: false,
    insertBoardError: false,
    deleteBoardLoading: false,
    deleteBoardDone: false,
    deleteBoardError: false,
}

export const loadBoardsRequestAction = (data) => {
    return {
        type: LOAD_BOARDS_REQUEST,
        data,
    }
}

export const loadBoardRequestAction = (data) => {
    return {
        type: LOAD_BOARD_REQUEST,
        data,
    }
}

export const updateBoardRequestAction = (data) => {
    return {
        type: UPDATE_BOARD_REQUEST,
        data,
    }
}

export const insertBoardRequestAction = (data) => {
    return {
        type: INSERT_BOARD_REQUEST,
        data
    }
}

export const deleteBoardRequestAction = (data) => {
    return {
        type: DELETE_BOARD_REQUEST,
        data
    }
}

const reducer = (state = initialState, action) => produce(state, (draft) => {
    switch (action.type) {
        case LOAD_BOARD_REQUEST:
            draft.loadBoardLoading = true;
            draft.loadBoardDone = false;
            draft.loadBoardError = null;
            break;
        case LOAD_BOARD_SUCCESS:
            draft.loadBoardLoading = false;
            draft.loadBoardDone = true;
            draft.board = action.data.data;
            break;
        case LOAD_BOARD_FAILURE:
            draft.loadBoardLoading = false;
            draft.loadBoardDone = false;
            draft.loadBoardError = action.data;
            break;
        case LOAD_BOARDS_REQUEST:
            draft.loadBoardsLoading = true;
            draft.loadBoardsDone = false;
            draft.loadBoardsError = null;
            break;
        case LOAD_BOARDS_SUCCESS:
            draft.loadBoardsLoading = false;
            draft.loadBoardsDone = true;
            draft.boards = action.data.data;
            draft.boardYesterday = action.data.boardYesterday;
            break;
        case LOAD_BOARDS_FAILURE:
            draft.loadBoardsLoading = false;
            draft.loadBoardsDone = false;
            draft.loadBoardsError = action.data;
            break;
        case INSERT_BOARD_REQUEST:
            draft.insertBoardLoading = true;
            draft.insertBoardDone = false;
            draft.insertBoardError = null;
            break;
        case INSERT_BOARD_SUCCESS:
            draft.insertBoardLoading = false;
            draft.insertBoardDone = true;
            break;
        case INSERT_BOARD_FAILURE:
            draft.insertBoardLoading = false;
            draft.insertBoardDone = false;
            draft.insertBoardError = action.data;
            break;
        case UPDATE_BOARD_REQUEST:
            draft.updateBoardLoading = true;
            draft.updateBoardDone = false;
            draft.updateBoardError = null;
            break;
        case UPDATE_BOARD_SUCCESS:
            draft.updateBoardLoading = false;
            draft.updateBoardDone = true;
            break;
        case UPDATE_BOARD_FAILURE:
            draft.updateBoardLoading = false;
            draft.updateBoardDone = false;
            draft.updateBoardError = action.data;
            break;
        case DELETE_BOARD_REQUEST:
            draft.deleteBoardLoading = true;
            draft.deleteBoardDone = false;
            draft.deleteBoardError = null;
            break;
        case DELETE_BOARD_SUCCESS:
            draft.deleteBoardLoading = false;
            draft.deleteBoardDone = true;
            break;
        case DELETE_BOARD_FAILURE:
            draft.deleteBoardLoading = false;
            draft.deleteBoardDone = false;
            draft.deleteBoardError = action.data;
            break;
    }
});

export default reducer;

