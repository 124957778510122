import styled from "styled-components";
import Label from "../../atoms/label";
import Input from "../../atoms/input";
import Button from "../../atoms/button";
import useInput from "../../../hooks/useInput";
import {useDispatch} from "react-redux";
import {useCallback} from "react";
import {insertUserRequestAction} from "../../../redux/user/reducer";

const JoinTemplate = () => {
    const dispatch = useDispatch();
    const [id, onChangeId, setId] = useInput('');
    const [password, onChangePassword, setPassword] = useInput('');
    const [passwordCheck, onChangePasswordCheck, setPasswordCheck] = useInput('');
    const [name, onChangeName, setName] = useInput('');
    const [email, onChangeEmail, setEmail] = useInput('');

    const onSubmit = useCallback(() => {
        if (!id || !password || !passwordCheck || !name || !email) {
            alert('모든 내용을 입력해주세요.');
            return false;
        }

        if (password !== passwordCheck) {
            alert('비밀번호가 맞지 않습니다.');
            return false;
        }

        if (!/^[a-z]+[a-z0-9]{3,20}$/.test(id)) {
            alert('아이디 형식이 맞지 않습니다.');
            return false;
        }

        if (!/^(?=.*[a-zA-z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+]).{6,20}$/.test(password)) {
            alert('패스워드 형식이 맞지 않습니다.');
            return false;
        }

        if (!/^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/.test(email)) {
            alert('이메일 형식이 맞지 않습니다.');
            return false;
        }

        if (!/^(?=.*[a-z0-9가-힣])[a-z0-9가-힣]{2,8}$/.test(name)) {
            alert('닉네임 형식이 맞지 않습니다.');
            return false;
        }

        dispatch(insertUserRequestAction({id, password, passwordCheck, name, email}));
    }, [id, password, passwordCheck, name, email]);

    return (
        <JoinWrap>
            <Label> 회원가입 </Label>

            <JoinItem>
                <Label>아이디</Label>
                <Input type={'text'} theme={'normal'} size={'sm'}  placeholder={'영문과 숫자를 포함한 5 ~ 11자'} value={id} onChange={onChangeId}></Input>
                <Label fontSize={'13px'} color={'#999'}>사용자 ID는 3~20자 사이의 영문 또는 숫자로 이루어져야 하며 영문으로 시작되어야 합니다.</Label>
            </JoinItem>

            <JoinItem>
                <Label>비밀번호</Label>
                <Input type={'password'} theme={'normal'} size={'sm'}  placeholder={'영문, 숫자, 특수문자 조합 최소 8자'} value={password} onChange={onChangePassword}></Input>
                <Input type={'password'} theme={'normal'} size={'sm'}  placeholder={'비밀번호 재입력'} value={passwordCheck} onChange={onChangePasswordCheck}></Input>
                <Label fontSize={'13px'} color={'#999'}>비밀번호는 6~20자 사이의 영문+숫자+특수문자로 이루어져야 합니다.</Label>
            </JoinItem>

            <JoinItem>
                <Label>이메일</Label>
                <Input type={'text'} theme={'normal'} size={'sm'}  placeholder={'이메일을 입력하세요.'} value={email} onChange={onChangeEmail}></Input>
                <Label fontSize={'13px'} color={'#999'}>메일 주소는 메일 인증 후 비밀번호 변경이나 찾기 등에 사용됩니다.</Label>
                {/*<Input type={'text'} theme={'normal'} placeholder={'인증번호를 입력하세요.'}></Input>*/}
                {/*<Button color={'normal'} shape={'rectangle'} size={'small'}>확인</Button>*/}
            </JoinItem>

            <JoinItem>
                <Label>닉네임</Label>
                <Input type={'text'} theme={'normal'} size={'sm'}  placeholder={'닉네임을 입력하세요'} value={name} onChange={onChangeName}></Input>
                <Label fontSize={'13px'} color={'#999'}>닉네임은 2~8자 이내여야 합니다.</Label>
            </JoinItem>

            <JoinButtonItem>
                <Label> <strong>이용약관</strong>, <strong>개인정보 수집 및 이용 내용</strong> 을 확인하였고 동의합니다.</Label>
                <Button color={'normal'} shape={'rectangle'} size={'middle'} onClick={onSubmit}>동의하고 가입하기</Button>
            </JoinButtonItem>
        </JoinWrap>
    )
};

const JoinWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 400px;
  margin: 0 auto;
  padding: 50px 20px;

  & > label {
    font-size: 36px;
    font-weight: 600;
    text-align: center;
    border-bottom: 4px solid #23C462;
    padding-bottom: 20px;
    margin-bottom: 10px;
  }

  & button {
    margin-top: 10px;
  }
`

const JoinItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;

  & label {
    font-weight: 700;
  }
`

const JoinButtonItem = styled.div`
  display: flex;
  flex-direction: column;

  & strong {
    color: #23C462;
    text-decoration: underline;
  }
`

export default JoinTemplate;
