export const LOAD_BOARDS_REQUEST = 'LOAD_BOARDS_REQUEST';
export const LOAD_BOARDS_SUCCESS = 'LOAD_BOARDS_SUCCESS';
export const LOAD_BOARDS_FAILURE = 'LOAD_BOARDS_FAILURE';

export const LOAD_BOARD_REQUEST = 'LOAD_BOARD_REQUEST';
export const LOAD_BOARD_SUCCESS = 'LOAD_BOARD_SUCCESS';
export const LOAD_BOARD_FAILURE = 'LOAD_BOARD_FAILURE';

export const INSERT_BOARD_REQUEST = 'INSERT_BOARD_REQUEST';
export const INSERT_BOARD_SUCCESS = 'INSERT_BOARD_SUCCESS';
export const INSERT_BOARD_FAILURE = 'INSERT_BOARD_FAILURE';

export const UPDATE_BOARD_REQUEST = 'UPDATE_BOARD_REQUEST';
export const UPDATE_BOARD_SUCCESS = 'UPDATE_BOARD_SUCCESS';
export const UPDATE_BOARD_FAILURE = 'UPDATE_BOARD_FAILURE';

export const DELETE_BOARD_REQUEST = 'DELETE_BOARD_REQUEST';
export const DELETE_BOARD_SUCCESS = 'DELETE_BOARD_SUCCESS';
export const DELETE_BOARD_FAILURE = 'DELETE_BOARD_FAILURE';
