import LayoutTemplate from "../../components/templates/layout";
import MyPageTemplate from "../../components/templates/mypage";

const MyPage = () => {
    return (
        <LayoutTemplate>
            <MyPageTemplate />
        </LayoutTemplate>
    )
}

export default MyPage;
