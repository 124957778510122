import styled from "styled-components";
import UserItem from "../../oraganisms/home/user";
import RankItem from "../../oraganisms/home/rank";
import Label from "../../atoms/label";
import Input from "../../atoms/input";
import {useCallback, useEffect, useState} from "react";
import Button from "../../atoms/button";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {deleteQuestionRequestAction, loadQuestionsRequestAction} from "../../../redux/question/reducer";
import {deleteWordRequestAction, loadWordsRequestAction} from "../../../redux/word/reducer";
import PayItem from "../../oraganisms/home/pay";
import pagination from "../../../utils/common/pagination";
import getQueryString from "../../../utils/getQueryString";
import history from "../../../utils/history";
import {useLocation} from "react-router-dom";

const MyPageTemplate = () => {
    const dispatch = useDispatch();
    const {page} = getQueryString();
    const questions = useSelector(((state) => state.question.questions), shallowEqual);
    const questionCount = useSelector(((state) => state.question.count), shallowEqual);
    const words = useSelector(((state) => state.word.words), shallowEqual);
    const wordCount = useSelector(((state) => state.word.count), shallowEqual);
    const [type, setType] = useState(0);
    const [paginate, setPaginate] = useState(pagination(questionCount, page || 1));
    const location = useLocation();

    useEffect(() => {
        if (type === 0) {
            dispatch(loadQuestionsRequestAction({page: page || 1}));
            setPaginate(pagination(questionCount, page || 1));
        } else {
            dispatch(loadWordsRequestAction({page: page || 1}));
            setPaginate(pagination(wordCount, page || 1));
        }
    }, [type, page, questionCount, wordCount]);

    const onDelete = useCallback((seq) => {
        if (window.confirm('정말 삭제하시겠습니까?')) {
            dispatch(type === 0 ? deleteQuestionRequestAction({seq}) : deleteWordRequestAction({seq}));
        }
    }, [type]);

    return (
        <MyPageWrap>
            <MyPageLeftWrap>
                <UserItem/>
                <PayItem/>
                <RankItem/>
            </MyPageLeftWrap>

            <MyPageRightWrap>
                <Label>MY 보관함</Label>

                <MyPageStatusHeaderWrap>
                    <Label className={type === 0 ? 'active' : ''} onClick={() => setType(0)}>학습현황</Label>
                    {/*<Label className={type === 1 ? 'active' : ''} onClick={() => setType(1)}>단어장</Label>*/}
                </MyPageStatusHeaderWrap>

                <MyPageStatusCountWrap>
                    <Label>전체 문장 개수 <strong>{type === 0 ? questions && questions.length : words && words.length}</strong>개</Label>
                </MyPageStatusCountWrap>

                {
                    type === 0 ?
                        <MyPageStatusQuestionWrap>
                            {
                                questions && questions.length > 0 &&
                                questions.map((i, index) =>
                                    <HomeCenterContentWrap key={index}>
                                        <HomeCenterContentItem>
                                            <Label>{index + 1}</Label>
                                            <Input theme={'normal'} value={i.question} disabled></Input>
                                            <Input theme={'normal'} value={i.questionTranslate} disabled></Input>
                                            <Button onClick={() => onDelete(i.seq)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                     viewBox="0 0 24 24" fill="none" stroke="#999"
                                                     strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                     className="feather feather-x">
                                                    <line x1="18" y1="6" x2="6" y2="18"/>
                                                    <line x1="6" y1="6" x2="18" y2="18"/>
                                                </svg>
                                            </Button>
                                        </HomeCenterContentItem>
                                        {
                                            i.feedback &&
                                            <HomeCenterAnswerItem>
                                                <Input theme={'normal'} value={i.feedback} disabled></Input>
                                            </HomeCenterAnswerItem>
                                        }
                                    </HomeCenterContentWrap>
                                )
                            }
                        </MyPageStatusQuestionWrap> :
                        <MyPageStatusQuestionWrap>
                            {
                                words && words.length > 0 &&
                                words.map((i, index) =>
                                    <HomeCenterContentItem key={index}>
                                        <Label>{index + 1}</Label>
                                        <Input theme={'normal'} value={i.word} disabled></Input>
                                        <Input theme={'normal'} value={i.meaning} disabled></Input>
                                        <Button onClick={() => onDelete(i.seq)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none" stroke="#999"
                                                 strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                 className="feather feather-x">
                                                <line x1="18" y1="6" x2="6" y2="18"/>
                                                <line x1="6" y1="6" x2="18" y2="18"/>
                                            </svg>
                                        </Button>
                                    </HomeCenterContentItem>
                                )
                            }
                        </MyPageStatusQuestionWrap>
                }

                <PaginationWrap>
                    {
                        !paginate.preventPrevPage &&
                        <>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                 onClick={() => history.replace(`?page=${paginate.bigPrevPage}`)}
                                 strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                                <polyline points="11 17 6 12 11 7"></polyline>
                                <polyline points="18 17 13 12 18 7"></polyline>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"
                                 onClick={() => history.replace(`?page=${Number(page) - 1}`)}
                                 stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"
                                 className="feather feather-chevron-left">
                                <polyline points="15 18 9 12 15 6"/>
                            </svg>
                        </>
                    }

                    {
                        paginate.pageBlock.map((i, index) => {
                            return (
                                <PaginationGoToButton onClick={() => history.push(`?page=${i}`)}
                                                      active={(Number(page) || 1) === i}
                                                      key={index}> {i} </PaginationGoToButton>
                            )
                        })
                    }

                    {
                        !paginate.preventNextPage &&
                        <>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"
                                 onClick={() => history.replace(`?page=${Number(page) + 1}`)}
                                 stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"
                                 className="feather feather-chevron-right">
                                <polyline points="9 18 15 12 9 6"/>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                 onClick={() => history.replace(`?page=${paginate.bigNextPage}`)}
                                 strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                                <polyline points="13 17 18 12 13 7"></polyline>
                                <polyline points="6 17 11 12 6 7"></polyline>
                            </svg>
                        </>
                    }
                </PaginationWrap>
            </MyPageRightWrap>
        </MyPageWrap>
    )
};

const MyPageWrap = styled.div`
  display: flex;
  gap: 20px;
  max-width: 1440px;
  margin: 0 auto;
  padding: 50px 20px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 20px;
  }
`

const MyPageLeftWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media screen and (max-width: 768px) {
    order: 0;
  }
`

const MyPageRightWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;

  & > label {
    font-size: 22px;
    font-weight: bold;
  }

  @media screen and (max-width: 768px) {
    order: 1;
  }
`

const MyPageStatusHeaderWrap = styled.div`
  display: flex;
  gap: 40px;
  border-bottom: 1px solid #eee;

  & label {
    font-size: 20px;
    padding-bottom: 10px;
    cursor: pointer;
  }

  & label.active {
    font-weight: bold;
    border-bottom: 4px solid #000;
    border-radius: 2px;
  }
`

const MyPageStatusCountWrap = styled.div`
  & strong {
    color: #23C462;
  }
`

const HomeCenterContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const HomeCenterAnswerItem = styled.div`
  padding-left: 45px;
  padding-right: 34px;

  @media screen and (max-width: 768px) {
    padding: 0;
  }
`

const HomeCenterContentItem = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  font-size: 13px;
  height: 35px;

  & > label {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    width: 35px;
    height: 35px;
    background-color: #23C462;
    color: #fff;
    border-radius: 8px;
  }

  & > input {
    flex: 1;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: auto;

    & > button {
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }
`

const MyPageStatusQuestionWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-top: 20px;

  & svg {
    cursor: pointer;
  }
`

const PaginationGoToButton = styled.button`
  color: ${({active}) => active ? '#23C462' : ''};
  width: 30px;
  height: 30px;
  border-radius: 6px;
`

export default MyPageTemplate;
