import LayoutTemplate from "../../components/templates/layout";
import HomeTemplate from "../../components/templates/home";
import {useDispatch} from "react-redux";
import {useCallback, useEffect} from "react";
import {loadTypesRequestAction} from "../../redux/type/reducer";

const Home = () => {
    const dispatch = useDispatch();

    const onLoad = useCallback(() => {
        dispatch(loadTypesRequestAction());
    }, []);

    useEffect(() => {
        onLoad();
    }, []);

    return (
        <LayoutTemplate>
            <HomeTemplate />
        </LayoutTemplate>
    )
}

export default Home;
