import Label from "../../atoms/label";
import Button from "../../atoms/button";
import history from "../../../utils/history";
import styled from "styled-components";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect} from "react";
import {loadUsersRankMeRequestAction, loginRequestAction, logoutRequestAction} from "../../../redux/user/reducer";
import useInput from "../../../hooks/useInput";

const UserItem = () => {
    const dispatch = useDispatch();
    const me = useSelector(((state) => state.user.me), shallowEqual);
    const userRank = useSelector(((state) => state.user.userRank), shallowEqual);
    const [id, onChangeId, setId] = useInput('');
    const [password, onChangePassword, setPassword] = useInput('');

    const onLogin = useCallback(() => {
        dispatch(loginRequestAction({id, password}));
    }, [id, password]);

    const onLogout = useCallback(() => {
        dispatch(logoutRequestAction());
    }, []);

    const onRankMe = useCallback(() => {
        dispatch(loadUsersRankMeRequestAction());
    }, []);

    useEffect(() => {
        onRankMe();
    }, []);

    return (
        me.seq &&
            <UserWrap>
                <UserTopWrap>
                    <Label>{me.name}</Label>

                    <UserTopButtonWrap>
                        <Button shape={'oval'} color={'gray'} padding={'5px'} onClick={() => history.push('/mypage')}>회원정보</Button>
                        <Button shape={'oval'} color={'gray'} padding={'5px'} onClick={onLogout}>로그아웃</Button>
                    </UserTopButtonWrap>
                </UserTopWrap>

                <UserBottomWrap>
                    <Label>누적 문장 개수 <strong> {me.totalQuestionCount} </strong></Label>
                    <Label>나의 주간 랭킹 <strong> {userRank} </strong></Label>
                </UserBottomWrap>
            </UserWrap>

            // <UserWrap>
            //     <Label>로그인</Label>
            //     <Input type={"text"} theme={'normal'} placeholder={'아이디'} value={id} onChange={onChangeId}/>
            //     <Input type={"password"} theme={'normal'} placeholder={'비밀번호'} value={password} onChange={onChangePassword}/>
            //     <Button shape={'rectangle'} size={'small'} color={'normal'} onClick={onLogin}>로그인</Button>
            // </UserWrap>
    )
}


const UserWrap = styled.div`
  min-width: 260px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #eee;
  gap: 10px;
  display: flex;
  flex-direction: column;

  & > label {
    font-size: 18px;
    font-weight: bold;
  }
`

const UserTopWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & label {
    font-size: 13px;
    font-weight: 600;
  }
`

const UserTopButtonWrap = styled.div`
  display: flex;
  font-size: 9px;
  gap: 10px;
`

const UserBottomWrap = styled.div`
  display: flex;
  gap: 15px;
  justify-content: space-between;

  & strong {
    color: #23C462
  }
`

export default UserItem;
