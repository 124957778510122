import axios from "axios";
import {
    LOAD_TYPES_FAILURE,
    LOAD_TYPES_REQUEST,
    LOAD_TYPES_SUCCESS,
} from "./constants";
import {all, fork, takeLatest, call, put} from 'redux-saga/effects';

function loadTypesAPI() {
    return axios.get('/vendor/api/v1/type')
}

function* loadTypes(action) {
    try {
        const result = yield call(loadTypesAPI, action.data);
        yield put({
            type: LOAD_TYPES_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: LOAD_TYPES_FAILURE,
            data: err.response.data,
        });
    }
}

function* watchLoadTypes() {
    yield takeLatest(LOAD_TYPES_REQUEST, loadTypes);
}

export default function* saga() {
    yield all([
        fork(watchLoadTypes),
    ])
}

