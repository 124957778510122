import axios from "axios";
import {
    DELETE_USER_FAILURE,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    INSERT_USER_FAILURE,
    INSERT_USER_REQUEST,
    INSERT_USER_SUCCESS,
    LOAD_USER_FAILURE,
    LOAD_USER_REQUEST,
    LOAD_USER_SUCCESS, LOAD_USERS_RANK_ALL_ME_FAILURE, LOAD_USERS_RANK_ALL_ME_REQUEST, LOAD_USERS_RANK_ALL_ME_SUCCESS,
    LOAD_USERS_RANK_FAILURE, LOAD_USERS_RANK_ME_FAILURE, LOAD_USERS_RANK_ME_REQUEST, LOAD_USERS_RANK_ME_SUCCESS,
    LOAD_USERS_RANK_REQUEST,
    LOAD_USERS_RANK_SUCCESS,
    LOG_IN_CHECK_FAILURE,
    LOG_IN_CHECK_REQUEST,
    LOG_IN_CHECK_SUCCESS,
    LOG_IN_FAILURE,
    LOG_IN_REQUEST,
    LOG_IN_SUCCESS,
    LOG_OUT_FAILURE,
    LOG_OUT_REQUEST,
    LOG_OUT_SUCCESS, UPDATE_USER_BOOKMARK_FAILURE,
    UPDATE_USER_BOOKMARK_REQUEST, UPDATE_USER_BOOKMARK_SUCCESS,
    UPDATE_USER_FAILURE,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS
} from "./constants";
import {all, fork, takeLatest, throttle, call, put} from 'redux-saga/effects';
import history from "../../utils/history";

function logInAPI(data) {
    return axios.post('/vendor/api/v1/user/login', data)
}

function logOutAPI(data) {
    return axios.post('/vendor/api/v1/user/logout', data)
}

function logInCheckAPI() {
    return axios.post('/vendor/api/v1/user/login/check');
}

function loadUserAPI(data) {
    return axios.get(`/vendor/api/v1/user/${data.seq}`)
}

function loadUsersRankAPI(data) {
    return axios.get(`/vendor/api/v1/user/rank`);
}

function loadUsersRankMeAPI(data) {
    return axios.get(`/vendor/api/v1/user/rank/me`);
}

function loadUsersRankAllMeAPI(data) {
    return axios.get(`/vendor/api/v1/user/rank/all/me`);
}

function insertUserAPI(data) {
    return axios.post('/vendor/api/v1/user/join', data)
}

function updateUserAPI(data) {
    return axios.put('/vendor/api/v1/user', data);
}

function updateUserBookmarkAPI(data) {
    return axios.put('/vendor/api/v1/user/bookmark', data)
}

function deleteUserAPI(data) {
    return axios.delete('/vendor/api/v1/user', {data})
}

function* logIn(action) {
    try {
        const result = yield call(logInAPI, action.data);

        yield put({
            type: LOG_IN_SUCCESS,
            data: result.data,
        });
        if (history.location.pathname !== '/') history.push('/');
    } catch (err) {
        yield put({
            type: LOG_IN_FAILURE,
            data: err.response.data,
        });
    }
}

function* logOut(action) {
    try {
        const result = yield call(logOutAPI, action.data);

        yield put({
            type: LOG_OUT_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: LOG_OUT_FAILURE,
            data: err.response.data,
        });
    }
}

function* logInCheck(action) {
    try {
        const result = yield call(logInCheckAPI, action.data);
        yield put({
            type: LOG_IN_CHECK_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: LOG_IN_CHECK_FAILURE,
            data: err.response.data,
        });
    }
}

function* loadUser(action) {
    try {
        const result = yield call(loadUserAPI, action.data);
        yield put({
            type: LOAD_USER_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: LOAD_USER_FAILURE,
            data: err.response.data,
        });
    }
}

function* loadUsersRank(action) {
    try {
        const result = yield call(loadUsersRankAPI, action.data);
        yield put({
            type: LOAD_USERS_RANK_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: LOAD_USERS_RANK_FAILURE,
            data: err.response.data,
        });
    }
}

function* loadUsersRankMe(action) {
    try {
        const result = yield call(loadUsersRankMeAPI, action.data);
        yield put({
            type: LOAD_USERS_RANK_ME_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: LOAD_USERS_RANK_ME_FAILURE,
            data: err.response.data,
        });
    }
}

function* loadUsersRankAllMe(action) {
    try {
        const result = yield call(loadUsersRankAllMeAPI, action.data);
        yield put({
            type: LOAD_USERS_RANK_ALL_ME_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: LOAD_USERS_RANK_ALL_ME_FAILURE,
            data: err.response.data,
        });
    }
}


function* insertUser(action) {
    try {
        const result = yield call(insertUserAPI, action.data);
        yield put({
            type: INSERT_USER_SUCCESS,
            data: result.data,
        });
        history.push('/');
    } catch (err) {
        yield put({
            type: INSERT_USER_FAILURE,
            data: err.response?.data,
        });
    }
}

function* updateUser(action) {
    try {
        const result = yield call(updateUserAPI, action.data);
        yield put({
            type: UPDATE_USER_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: UPDATE_USER_FAILURE,
            data: err.response.data,
        });
    }
}

function* updateUserBookmark(action) {
    try {
        const result = yield call(updateUserBookmarkAPI, action.data);
        yield put({
            type: UPDATE_USER_BOOKMARK_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: UPDATE_USER_BOOKMARK_FAILURE,
            data: err.response.data,
        });
    }
}

function* deleteUser(action) {
    try {
        const result = yield call(deleteUserAPI, action.data);
        yield put({
            type: DELETE_USER_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: DELETE_USER_FAILURE,
            data: err.response.data,
        });
    }
}

function* watchLogIn() {
    yield throttle(1000, LOG_IN_REQUEST, logIn);
}

function* watchLogOut() {
    yield throttle(1000, LOG_OUT_REQUEST, logOut);
}

function* watchLogInCheck() {
    yield takeLatest(LOG_IN_CHECK_REQUEST, logInCheck);
}

function* watchLoadUser() {
    yield takeLatest(LOAD_USER_REQUEST, loadUser);
}

function* watchLoadUsersRank() {
    yield takeLatest(LOAD_USERS_RANK_REQUEST, loadUsersRank);
}

function* watchLoadUsersRankMe() {
    yield throttle(1000, LOAD_USERS_RANK_ME_REQUEST, loadUsersRankMe);
}

function* watchLoadUsersRankAllMe() {
    yield throttle(1000, LOAD_USERS_RANK_ALL_ME_REQUEST, loadUsersRankAllMe);
}

function* watchInsertUser() {
    yield throttle(1000, INSERT_USER_REQUEST, insertUser);
}

function* watchUpdateUser() {
    yield takeLatest(UPDATE_USER_REQUEST, updateUser);
}

function* watchUpdateUserBookmark() {
    yield throttle(1000, UPDATE_USER_BOOKMARK_REQUEST, updateUserBookmark);
}

function* watchDeleteUser() {
    yield takeLatest(DELETE_USER_REQUEST, deleteUser);
}

export default function* saga() {
    yield all([
        fork(watchLogIn),
        fork(watchLogOut),
        fork(watchLogInCheck),
        fork(watchLoadUser),
        fork(watchLoadUsersRank),
        fork(watchLoadUsersRankMe),
        fork(watchLoadUsersRankAllMe),
        fork(watchInsertUser),
        fork(watchUpdateUser),
        fork(watchUpdateUserBookmark),
        fork(watchDeleteUser),
    ])
}

