import styled from "styled-components";
import Label from "../../atoms/label";
import introduce1 from "../../../assets/images/introduce1.png";
import introduce2 from "../../../assets/images/introduce2.png";

const IntroduceTemplate = () => {
    return (
        <IntroduceWrap>
            <IntroduceTopWrap>
                <IntroduceTopLeftWrap>
                    <Label>FrameCatch</Label>
                </IntroduceTopLeftWrap>

                <IntroduceTopRightWrap>
                    <img src={introduce1} alt={''}></img>
                </IntroduceTopRightWrap>
            </IntroduceTopWrap>

            <IntroduceMiddleWrap>
                <div>
                    프레임캐치는 “기초유: 기초를 유창하게"라는 목표를 가지고 외국어 학습에서 가장 중요한
                    “습관 기르기”를 위한 학습자들의 공간입니다. 쓰기를 통해 습관을 기르도록 도와주며,
                    앞으로 영어뿐만 아니라 다양한 외국어 학습을 위한 공간이 될 것입니다.
                    <br/>
                    <br/>
                    영어를 처음 배우는"누구라도” 첫 날부터 문장 쓰기가 가능한 틀을 제공해드립니다.
                </div>
            </IntroduceMiddleWrap>

            <IntroduceBottomWrap>
                <img src={introduce2} alt={''}></img>

                <IntroduceBottomTextWrap>
                    <Label>프레임캐치와 함께</Label>
                    <Label>언제 어디서나 문장을 만들어보세요</Label>
                </IntroduceBottomTextWrap>
            </IntroduceBottomWrap>
        </IntroduceWrap>
    )
};

const IntroduceWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  max-width: 1140px;
  margin: 0 auto;
  padding-top: 50px;
`

const IntroduceTopWrap = styled.div`
  display: flex;
  gap: 100px;
  padding: 0 15px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`

const IntroduceMiddleWrap = styled.div`
  display: flex;
  margin: 0 0 0 auto;
  gap: 60px;
  padding: 0 4vw;
  max-width: 1000px;

  & > div {
    font-size: 16px;
    color: #666;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    
    & img {
      max-width: 70%;
      margin: 0 auto;
    }
  }
`

const IntroduceBottomWrap = styled.div`
  position: relative;
  
  & img {
    width: 100%;
  }
`

const IntroduceBottomTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  
  & label {
    font-size: 50px;
    color: #fff;
  }

  & label:nth-child(2) {
    font-weight: 700;
  }

  @media screen and (max-width: 768px) {
    & label {
      font-size: 20px;
    }
  }
`

const IntroduceTopLeftWrap = styled.div`
  display: flex;
  flex-direction: column;

  & label {
    font-size: 48px;
    font-weight: 700;
  }
`

const IntroduceTopRightWrap = styled.div`
    & img {
      max-width: 100%;
    }

`

export default IntroduceTemplate;
