import styled from "styled-components";

const RankTemplate = () => {
    return (
        <RankWrap>

        </RankWrap>
    )
};

const RankWrap = styled.div`
  display: flex;
  gap: 20px;
  max-width: 1440px;
  margin: 0 auto;
  padding: 50px 0;
`

export default RankTemplate;
