import axios from "axios";
import {
    DELETE_WORD_FAILURE,
    DELETE_WORD_REQUEST,
    DELETE_WORD_SUCCESS,
    INSERT_WORD_FAILURE,
    INSERT_WORD_REQUEST,
    INSERT_WORD_SUCCESS,
    LOAD_WORD_FAILURE,
    LOAD_WORD_REQUEST,
    LOAD_WORD_SUCCESS,
    LOAD_WORDS_FAILURE,
    LOAD_WORDS_RANDOM_FAILURE,
    LOAD_WORDS_RANDOM_REQUEST,
    LOAD_WORDS_RANDOM_SUCCESS, LOAD_WORDS_REQUEST, LOAD_WORDS_SUCCESS,
    UPDATE_WORD_FAILURE,
    UPDATE_WORD_REQUEST,
    UPDATE_WORD_SUCCESS
} from "./constants";
import {all, fork, takeLatest, throttle, call, put} from 'redux-saga/effects';
import history from "../../utils/history";

function loadWordAPI(data) {
    return axios.get(`/vendor/api/v1/word/${data.seq}`)
}

function loadWordsAPI(data) {
    return axios.get(`/vendor/api/v1/word/${data.page}`)
}

function loadWordsRandomAPI(data) {
    return axios.get(`/vendor/api/v1/word/random/${data.type}/${data.rememberMode}`)
}

function insertWordAPI(data) {
    return axios.post('/vendor/api/v1/word/join', data)
}

function updateWordAPI(data) {
    return axios.put('/vendor/api/v1/word', data);
}

function deleteWordAPI(data) {
    return axios.delete('/vendor/api/v1/word', {data})
}

function* loadWord(action) {
    try {
        const result = yield call(loadWordAPI, action.data);
        yield put({
            type: LOAD_WORD_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: LOAD_WORD_FAILURE,
            data: err.response.data,
        });
    }
}

function* loadWords(action) {
    try {
        const result = yield call(loadWordsAPI, action.data);
        yield put({
            type: LOAD_WORDS_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: LOAD_WORDS_FAILURE,
            data: err.response.data,
        });
    }
}

function* loadWordsRandom(action) {
    try {
        const result = yield call(loadWordsRandomAPI, action.data);
        yield put({
            type: LOAD_WORDS_RANDOM_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: LOAD_WORDS_RANDOM_FAILURE,
            data: err.response.data,
        });
    }
}

function* insertWord(action) {
    try {
        const result = yield call(insertWordAPI, action.data);
        yield put({
            type: INSERT_WORD_SUCCESS,
            data: result.data,
        });
        history.push('/');
    } catch (err) {
        yield put({
            type: INSERT_WORD_FAILURE,
            data: err.response?.data,
        });
    }
}

function* updateWord(action) {
    try {
        const result = yield call(updateWordAPI, action.data);
        yield put({
            type: UPDATE_WORD_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: UPDATE_WORD_FAILURE,
            data: err.response.data,
        });
    }
}

function* deleteWord(action) {
    try {
        const result = yield call(deleteWordAPI, action.data);
        yield put({
            type: DELETE_WORD_SUCCESS,
            data: {...result.data, ...action.data},
        });
    } catch (err) {
        yield put({
            type: DELETE_WORD_FAILURE,
            data: err.response.data,
        });
    }
}

function* watchLoadWord() {
    yield takeLatest(LOAD_WORD_REQUEST, loadWord);
}

function* watchLoadWords() {
    yield takeLatest(LOAD_WORDS_REQUEST, loadWords);
}

function* watchLoadWordsRandom() {
    yield takeLatest(LOAD_WORDS_RANDOM_REQUEST, loadWordsRandom);
}

function* watchInsertWord() {
    yield throttle(1000, INSERT_WORD_REQUEST, insertWord);
}

function* watchUpdateWord() {
    yield takeLatest(UPDATE_WORD_REQUEST, updateWord);
}

function* watchDeleteWord() {
    yield throttle(1000, DELETE_WORD_REQUEST, deleteWord);
}

export default function* saga() {
    yield all([
        fork(watchLoadWord),
        fork(watchLoadWords),
        fork(watchLoadWordsRandom),
        fork(watchInsertWord),
        fork(watchUpdateWord),
        fork(watchDeleteWord),
    ])
}

