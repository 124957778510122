import styled from "styled-components";
import Button from "../../atoms/button";
import history from "../../../utils/history";
import Label from "../../atoms/label";

const FindTemplate = () => {
    return (
        <FindWrap>
            <FindItem>
                <Label> ID 찾기 </Label>
                <Label> 인증내역을 통해 회원 ID를 알려드립니다. </Label>
                <Button shape={'rectangle'} size={'middle'} color={'normal'} onClick={() => history.push('/find/id')}>확인</Button>
            </FindItem>

            <SeparateWrap/>

            <FindItem>
                <Label> PW 찾기 </Label>
                <Label> 인증내역을 통해 PW를 재설정 합니다. </Label>
                <Button shape={'rectangle'} size={'middle'} color={'normal'} onClick={() => history.push('/find/pw')}>확인</Button>
            </FindItem>
        </FindWrap>
    )
};

const FindWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 400px;
  margin: 0 auto;
  padding: 50px 20px;
`

const FindItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  
  & label:first-child {
    font-weight: 700;
  }
`

const SeparateWrap = styled.div`
  margin: 3vw 0;
  border-top: 1px solid #eee;
`

export default FindTemplate;
