export const LOAD_WORD_REQUEST = 'LOAD_WORD_REQUEST';
export const LOAD_WORD_SUCCESS = 'LOAD_WORD_SUCCESS';
export const LOAD_WORD_FAILURE = 'LOAD_WORD_FAILURE';

export const LOAD_WORDS_REQUEST = 'LOAD_WORDS_REQUEST';
export const LOAD_WORDS_SUCCESS = 'LOAD_WORDS_SUCCESS';
export const LOAD_WORDS_FAILURE = 'LOAD_WORDS_FAILURE';

export const LOAD_WORDS_RANDOM_REQUEST = 'LOAD_WORD_RANDOM_REQUEST';
export const LOAD_WORDS_RANDOM_SUCCESS = 'LOAD_WORD_RANDOM_SUCCESS';
export const LOAD_WORDS_RANDOM_FAILURE = 'LOAD_WORD_RANDOM_FAILURE';

export const INSERT_WORD_REQUEST = 'INSERT_WORD_REQUEST';
export const INSERT_WORD_SUCCESS = 'INSERT_WORD_SUCCESS';
export const INSERT_WORD_FAILURE = 'INSERT_WORD_FAILURE';

export const UPDATE_WORD_REQUEST = 'UPDATE_WORD_REQUEST';
export const UPDATE_WORD_SUCCESS = 'UPDATE_WORD_SUCCESS';
export const UPDATE_WORD_FAILURE = 'UPDATE_WORD_FAILURE';

export const DELETE_WORD_REQUEST = 'DELETE_WORD_REQUEST';
export const DELETE_WORD_SUCCESS = 'DELETE_WORD_SUCCESS';
export const DELETE_WORD_FAILURE = 'DELETE_WORD_FAILURE';
