import {
    DELETE_WORDS_REMEMBER_FAILURE,
    DELETE_WORDS_REMEMBER_REQUEST,
    DELETE_WORDS_REMEMBER_SUCCESS,
    INSERT_WORDS_REMEMBER_FAILURE,
    INSERT_WORDS_REMEMBER_REQUEST,
    INSERT_WORDS_REMEMBER_SUCCESS, LOAD_WORDS_REMEMBER_FAILURE,
    LOAD_WORDS_REMEMBER_REQUEST, LOAD_WORDS_REMEMBER_SUCCESS,
    LOAD_WORDS_REMEMBERS_FAILURE,
    LOAD_WORDS_REMEMBERS_REQUEST,
    LOAD_WORDS_REMEMBERS_SUCCESS,
    UPDATE_WORDS_REMEMBER_FAILURE, UPDATE_WORDS_REMEMBER_REQUEST, UPDATE_WORDS_REMEMBER_SUCCESS
} from "./constants";
import produce from "immer";

const initialState = {
    wordsRemember: {},
    wordsRemembers: [],
    loadWordsRemembersLoading: false,
    loadWordsRemembersDone: false,
    loadWordsRemembersError: false,
    loadWordsRememberLoading: false,
    loadWordsRememberDone: false,
    loadWordsRememberError: false,
    insertWordsRememberLoading: false,
    insertWordsRememberDone: false,
    insertWordsRememberError: false,
    deleteWordsRememberLoading: false,
    deleteWordsRememberDone: false,
    deleteWordsRememberError: false,
}

export const loadWordsRemembersRequestAction = (data) => {
    return {
        type: LOAD_WORDS_REMEMBERS_REQUEST,
        data,
    }
}

export const loadWordsRememberRequestAction = (data) => {
    return {
        type: LOAD_WORDS_REMEMBER_REQUEST,
        data,
    }
}

export const updateWordsRememberRequestAction = (data) => {
    return {
        type: UPDATE_WORDS_REMEMBER_REQUEST,
        data,
    }
}

export const insertWordsRememberRequestAction = (data) => {
    return {
        type: INSERT_WORDS_REMEMBER_REQUEST,
        data
    }
}

export const deleteWordsRememberRequestAction = (data) => {
    return {
        type: DELETE_WORDS_REMEMBER_REQUEST,
        data
    }
}

const reducer = (state = initialState, action) => produce(state, (draft) => {
    switch (action.type) {
        case LOAD_WORDS_REMEMBER_REQUEST:
            draft.loadWordsRememberLoading = true;
            draft.loadWordsRememberDone = false;
            draft.loadWordsRememberError = null;
            break;
        case LOAD_WORDS_REMEMBER_SUCCESS:
            draft.loadWordsRememberLoading = false;
            draft.loadWordsRememberDone = true;
            draft.wordsRemember = action.data.data;
            break;
        case LOAD_WORDS_REMEMBER_FAILURE:
            draft.loadWordsRememberLoading = false;
            draft.loadWordsRememberDone = false;
            draft.loadWordsRememberError = action.data;
            break;
        case LOAD_WORDS_REMEMBERS_REQUEST:
            draft.loadWordsRemembersLoading = true;
            draft.loadWordsRemembersDone = false;
            draft.loadWordsRemembersError = null;
            break;
        case LOAD_WORDS_REMEMBERS_SUCCESS:
            draft.loadWordsRemembersLoading = false;
            draft.loadWordsRemembersDone = true;
            draft.wordsRemember = action.data.data;
            break;
        case LOAD_WORDS_REMEMBERS_FAILURE:
            draft.loadWordsRemembersLoading = false;
            draft.loadWordsRemembersDone = false;
            draft.loadWordsRemembersError = action.data;
            break;
        case INSERT_WORDS_REMEMBER_REQUEST:
            draft.insertWordsRememberLoading = true;
            draft.insertWordsRememberDone = false;
            draft.insertWordsRememberError = null;
            break;
        case INSERT_WORDS_REMEMBER_SUCCESS:
            draft.insertWordsRememberLoading = false;
            draft.insertWordsRememberDone = true;
            break;
        case INSERT_WORDS_REMEMBER_FAILURE:
            draft.insertWordsRememberLoading = false;
            draft.insertWordsRememberDone = false;
            draft.insertWordsRememberError = action.data;
            break;
        case UPDATE_WORDS_REMEMBER_REQUEST:
            draft.updateWordsRememberLoading = true;
            draft.updateWordsRememberDone = false;
            draft.updateWordsRememberError = null;
            break;
        case UPDATE_WORDS_REMEMBER_SUCCESS:
            draft.updateWordsRememberLoading = false;
            draft.updateWordsRememberDone = true;
            break;
        case UPDATE_WORDS_REMEMBER_FAILURE:
            draft.updateWordsRememberLoading = false;
            draft.updateWordsRememberDone = false;
            draft.updateWordsRememberError = action.data;
            break;
        case DELETE_WORDS_REMEMBER_REQUEST:
            draft.deleteWordsRememberLoading = true;
            draft.deleteWordsRememberDone = false;
            draft.deleteWordsRememberError = null;
            break;
        case DELETE_WORDS_REMEMBER_SUCCESS:
            draft.deleteWordsRememberLoading = false;
            draft.deleteWordsRememberDone = true;
            break;
        case DELETE_WORDS_REMEMBER_FAILURE:
            draft.deleteWordsRememberLoading = false;
            draft.deleteWordsRememberDone = false;
            draft.deleteWordsRememberError = action.data;
            break;
    }
});

export default reducer;

