import LayoutTemplate from "../../components/templates/layout";
import {useCallback, useEffect} from "react";
import {useDispatch} from "react-redux";
import {loadBoardsRequestAction} from "../../redux/board/reducer";
import {useParams} from "react-router-dom";
import BoardTemplate from "../../components/templates/board";

const Board = () => {
    const {categorySeq} = useParams();
    const dispatch = useDispatch();

    const onLoadBoard = useCallback(() => {
        dispatch(loadBoardsRequestAction({categorySeq}));
    }, [categorySeq]);

    useEffect(() => {
        onLoadBoard();
    }, [categorySeq]);

    return (
        <LayoutTemplate>
            <BoardTemplate/>
        </LayoutTemplate>
    )
}

export default Board;
