import {
    DELETE_USER_FAILURE,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    INSERT_USER_FAILURE,
    INSERT_USER_REQUEST,
    INSERT_USER_SUCCESS,
    LOAD_USER_FAILURE,
    LOAD_USER_REQUEST,
    LOAD_USER_SUCCESS, LOAD_USERS_RANK_ALL_ME_FAILURE, LOAD_USERS_RANK_ALL_ME_REQUEST, LOAD_USERS_RANK_ALL_ME_SUCCESS,
    LOAD_USERS_RANK_FAILURE, LOAD_USERS_RANK_ME_FAILURE, LOAD_USERS_RANK_ME_REQUEST, LOAD_USERS_RANK_ME_SUCCESS,
    LOAD_USERS_RANK_REQUEST,
    LOAD_USERS_RANK_SUCCESS,
    LOG_IN_CHECK_FAILURE,
    LOG_IN_CHECK_REQUEST,
    LOG_IN_CHECK_SUCCESS,
    LOG_IN_FAILURE,
    LOG_IN_REQUEST,
    LOG_IN_SUCCESS,
    LOG_OUT_FAILURE,
    LOG_OUT_REQUEST,
    LOG_OUT_SUCCESS, UPDATE_USER_BOOKMARK_FAILURE,
    UPDATE_USER_BOOKMARK_REQUEST, UPDATE_USER_BOOKMARK_SUCCESS,
    UPDATE_USER_FAILURE,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS
} from "./constants";
import produce from "immer";

const initialState = {
    me: {},
    user: {},
    userRank: 0,
    usersRank: [],
    logInLoading: false,
    logInDone: false,
    logInError: false,
    logOutLoading: false,
    logOutDone: false,
    logOutError: false,
    logInCheckLoading: false,
    logInCheckDone: false,
    logInCheckError: false,
    loadUserLoading: false,
    loadUserDone: false,
    loadUserError: false,
    loadUsersRankLoading: false,
    loadUsersRankDone: false,
    loadUsersRankError: false,
    loadUsersRankMeLoading: false,
    loadUsersRankMeDone: false,
    loadUsersRankMeError: false,
    loadUsersRankAllMeLoading: false,
    loadUsersRankAllMeDone: false,
    loadUsersRankAllMeError: false,
    insertUserLoading: false,
    insertUserDone: false,
    insertUserError: null,
    updateUserBookmarkLoading: false,
    updateUserBookmarkDone: false,
    updateUserBookmarkError: false,
    deleteUserLoading: false,
    deleteUserDone: false,
    deleteUserError: false,
}

export const loginRequestAction = (data) => {
    return {
        type: LOG_IN_REQUEST,
        data,
    }
}

export const logoutRequestAction = (data) => {
    return {
        type: LOG_OUT_REQUEST,
        data,
    }
}

export const loginCheckRequestAction = (data) => {
    return {
        type: LOG_IN_CHECK_REQUEST,
        data,
    }
}

export const loadUserRequestAction = (data) => {
    return {
        type: LOAD_USER_REQUEST,
        data,
    }
}

export const loadUsersRankRequestAction = (data) => {
    return {
        type: LOAD_USERS_RANK_REQUEST,
        data,
    }
}

export const loadUsersRankMeRequestAction = (data) => {
    return {
        type: LOAD_USERS_RANK_ME_REQUEST,
        data,
    }
}

export const loadUsersRankAllMeRequestAction = (data) => {
    return {
        type: LOAD_USERS_RANK_ALL_ME_REQUEST,
        data,
    }
}

export const insertUserRequestAction = (data) => {
    return {
        type: INSERT_USER_REQUEST,
        data
    }
}

export const updateUserRequestAction = (data) => {
    return {
        type: UPDATE_USER_REQUEST,
        data,
    }
}

export const updateUserBookmarkRequestAction = (data) => {
    return {
        type: UPDATE_USER_BOOKMARK_REQUEST,
        data
    }
}

export const deleteUserRequestAction = () => {
    return {
        type: DELETE_USER_REQUEST,
    }
}

const reducer = (state = initialState, action) => produce(state, (draft) => {
    switch (action.type) {
        case LOG_IN_REQUEST:
            draft.logInLoading = true;
            draft.logInDone = false;
            draft.logInError = null;
            break;
        case LOG_IN_SUCCESS:
            draft.logInLoading = false;
            draft.logInDone = true;
            draft.me = action.data;
            break;
        case LOG_IN_FAILURE:
            draft.logInLoading = false;
            draft.logInDone = false;
            draft.logInError = action.data;
            break;
        case LOG_OUT_REQUEST:
            draft.logOutLoading = true;
            draft.logOutDone = false;
            draft.logOutError = null;
            break;
        case LOG_OUT_SUCCESS:
            draft.logOutLoading = false;
            draft.logOutDone = true;
            draft.me = {};
            break;
        case LOG_OUT_FAILURE:
            draft.logOutLoading = false;
            draft.logOutDone = false;
            draft.logOutError = action.data;
            break;
        case LOG_IN_CHECK_REQUEST:
            draft.logInCheckLoading = true;
            draft.logInCheckDone = false;
            draft.logInCheckError = null;
            break;
        case LOG_IN_CHECK_SUCCESS:
            draft.logInCheckLoading = false;
            draft.logInCheckDone = true;
            draft.me = action.data;
            break;
        case LOG_IN_CHECK_FAILURE:
            draft.logInCheckLoading = false;
            draft.logInCheckDone = false;
            draft.logInCheckError = action.data;
            break;
        case LOAD_USER_REQUEST:
            draft.loadUserLoading = true;
            draft.loadUserDone = false;
            draft.loadUserError = null;
            break;
        case LOAD_USER_SUCCESS:
            draft.loadUserLoading = false;
            draft.loadUserDone = true;
            draft.user = action.data.data;
            break;
        case LOAD_USER_FAILURE:
            draft.loadUserLoading = false;
            draft.loadUserDone = false;
            draft.loadUserError = action.data;
            break;
        case LOAD_USERS_RANK_REQUEST:
            draft.loadUsersRankLoading = true;
            draft.loadUsersRankDone = false;
            draft.loadUsersRankError = null;
            break;
        case LOAD_USERS_RANK_SUCCESS:
            draft.loadUsersRankLoading = false;
            draft.loadUsersRankDone = true;
            draft.rank = action.data.data;
            break;
        case LOAD_USERS_RANK_FAILURE:
            draft.loadUsersRankLoading = false;
            draft.loadUsersRankDone = false;
            draft.loadUsersRankError = action.data;
            break;
        case LOAD_USERS_RANK_ME_REQUEST:
            draft.loadUsersRankMeLoading = true;
            draft.loadUsersRankMeDone = false;
            draft.loadUsersRankMeError = null;
            break;
        case LOAD_USERS_RANK_ME_SUCCESS:
            draft.loadUsersRankMeLoading = false;
            draft.loadUsersRankMeDone = true;
            draft.userRank = action.data.data;
            break;
        case LOAD_USERS_RANK_ME_FAILURE:
            draft.loadUsersRankMeLoading = false;
            draft.loadUsersRankMeDone = false;
            draft.loadUsersRankMeError = action.data;
            break;
        case LOAD_USERS_RANK_ALL_ME_REQUEST:
            draft.loadUsersRankAllMeLoading = true;
            draft.loadUsersRankAllMeDone = false;
            draft.loadUsersRankAllMeError = null;
            break;
        case LOAD_USERS_RANK_ALL_ME_SUCCESS:
            draft.loadUsersRankAllMeLoading = false;
            draft.loadUsersRankAllMeDone = true;
            draft.rank = action.data.data;
            break;
        case LOAD_USERS_RANK_ALL_ME_FAILURE:
            draft.loadUsersRankAllMeLoading = false;
            draft.loadUsersRankAllMeDone = false;
            draft.loadUsersRankAllMeError = action.data;
            break;
        case INSERT_USER_REQUEST:
            draft.insertUserLoading = true;
            draft.insertUserDone = false;
            draft.insertUserError = null;
            break;
        case INSERT_USER_SUCCESS:
            draft.insertUserLoading = false;
            draft.insertUserDone = true;
            break;
        case INSERT_USER_FAILURE:
            draft.insertUserLoading = false;
            draft.insertUserDone = false;
            draft.insertUserError = action.data;
            break;
        case UPDATE_USER_REQUEST:
            draft.updateUserLoading = true;
            draft.updateUserDone = false;
            draft.updateUserError = null;
            break;
        case UPDATE_USER_SUCCESS:
            draft.updateUserLoading = false;
            draft.updateUserDone = true;
            break;
        case UPDATE_USER_FAILURE:
            draft.updateUserLoading = false;
            draft.updateUserDone = false;
            draft.updateUserError = action.data;
            break;
        case UPDATE_USER_BOOKMARK_REQUEST:
            draft.updateUserBookmarkLoading = true;
            draft.updateUserBookmarkDone = false;
            draft.updateUserBookmarkError = null;
            break;
        case UPDATE_USER_BOOKMARK_SUCCESS:
            draft.updateUserBookmarkLoading = false;
            draft.updateUserBookmarkDone = true;
            break;
        case UPDATE_USER_BOOKMARK_FAILURE:
            draft.updateUserBookmarkLoading = false;
            draft.updateUserBookmarkDone = false;
            draft.updateUserBookmarkError = action.data;
            break;
        case DELETE_USER_REQUEST:
            draft.deleteUserLoading = true;
            draft.deleteUserDone = false;
            draft.deleteUserError = null;
            break;
        case DELETE_USER_SUCCESS:
            draft.deleteUserLoading = false;
            draft.deleteUserDone = true;
            break;
        case DELETE_USER_FAILURE:
            draft.deleteUserLoading = false;
            draft.deleteUserDone = false;
            draft.deleteUserError = action.data;
            break;
    }
});

export default reducer;

