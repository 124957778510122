import {
    LOAD_TYPES_FAILURE,
    LOAD_TYPES_REQUEST,
    LOAD_TYPES_SUCCESS,
} from "./constants";
import produce from "immer";

const initialState = {
    types: [],
    loadTypesLoading: false,
    loadTypesDone: false,
    loadTypesError: false,
}

export const loadTypesRequestAction = (data) => {
    return {
        type: LOAD_TYPES_REQUEST,
        data,
    }
}

const reducer = (state = initialState, action) => produce(state, (draft) => {
    switch (action.type) {
        case LOAD_TYPES_REQUEST:
            draft.loadTypesLoading = true;
            draft.loadTypesDone = false;
            draft.loadTypesError = null;
            break;
        case LOAD_TYPES_SUCCESS:
            draft.loadTypesLoading = false;
            draft.loadTypesDone = true;
            draft.types = action.data.data;
            break;
        case LOAD_TYPES_FAILURE:
            draft.loadTypesLoading = false;
            draft.loadTypesDone = false;
            draft.loadTypesError = action.data;
            break;
    }
});

export default reducer;

