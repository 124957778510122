import styled from "styled-components";

const Switch = ({...props}) => {
    return (
        <>
            <SwitchInput type={'checkbox'} {...props}/>
            <SwitchLabel htmlFor={props.id}></SwitchLabel>
        </>
    )
};

const SwitchInput = styled.input`
  &:checked+label:before {
    transform: translateX(22.4px);
    background-color: #23C462;
  }
`

const SwitchLabel = styled.label`
  position: relative;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fbfbfb;
  border-radius: 34px;
  border: 1px solid #e6e6e6;
  width: 48px;
  height: 24px;

  &:before {
    height: 14.4px;
    width: 14.4px;
    left: 5px;
    bottom: 4px;
    border-radius: 50%;
    position: absolute;
    content: "";
    background-color: #858585;
    transition: all 0.4s;
  }   
`

export default Switch;
