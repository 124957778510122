import styled from "styled-components";

const Label = ({children, ...props}) => {
    return (
        <LabelWrap {...props}>
            {children}
        </LabelWrap>
    )
}

const LabelWrap = styled.label`
  display: ${({display}) => display};
  font-size: ${({fontSize}) => fontSize};
  font-weight: ${({fontWeight}) => fontWeight};
  color: ${({color}) => color};
  margin: ${({margin}) => margin};
  flex: ${({flex}) => flex};
  flex-wrap: ${({flexWrap}) => flexWrap};
  align-items: ${({alignItems}) => alignItems};
  text-align: ${({textAlign}) => textAlign};
  min-width: ${({minWidth}) => minWidth};
  min-width: ${({minWidth}) => minWidth};
  cursor: ${({cursor}) => cursor};
`;

export default Label;
