import LayoutTemplate from "../../components/templates/layout";
import JoinTemplate from "../../components/templates/join";

const Join = () => {
    return (
        <LayoutTemplate>
            <JoinTemplate />
        </LayoutTemplate>
    )
}

export default Join;
