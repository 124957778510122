import {
    DELETE_WORD_FAILURE,
    DELETE_WORD_REQUEST,
    DELETE_WORD_SUCCESS,
    INSERT_WORD_FAILURE,
    INSERT_WORD_REQUEST,
    INSERT_WORD_SUCCESS,
    LOAD_WORD_FAILURE,
    LOAD_WORD_REQUEST,
    LOAD_WORD_SUCCESS, LOAD_WORDS_FAILURE,
    LOAD_WORDS_RANDOM_FAILURE,
    LOAD_WORDS_RANDOM_REQUEST,
    LOAD_WORDS_RANDOM_SUCCESS,
    LOAD_WORDS_REQUEST, LOAD_WORDS_SUCCESS,
    UPDATE_WORD_FAILURE,
    UPDATE_WORD_REQUEST,
    UPDATE_WORD_SUCCESS
} from "./constants";
import produce from "immer";

const initialState = {
    word: {},
    words: [],
    count: 0,
    wordsRandom: [],
    loadWordLoading: false,
    loadWordDone: false,
    loadWordError: false,
    loadWordsLoading: false,
    loadWordsDone: false,
    loadWordsError: false,
    loadWordsRandomLoading: false,
    loadWordsRandomDone: false,
    loadWordsRandomError: false,
    insertWordLoading: false,
    insertWordDone: false,
    insertWordError: null,
    deleteWordLoading: false,
    deleteWordDone: false,
    deleteWordError: false,
}

export const loadWordRequestAction = (data) => {
    return {
        type: LOAD_WORD_REQUEST,
        data,
    }
}

export const loadWordsRequestAction = (data) => {
    return {
        type: LOAD_WORDS_REQUEST,
        data,
    }
}

export const loadWordsRandomRequestAction = (data) => {
    return {
        type: LOAD_WORDS_RANDOM_REQUEST,
        data,
    }
}

export const insertWordRequestAction = (data) => {
    return {
        type: INSERT_WORD_REQUEST,
        data
    }
}

export const updateWordRequestAction = (data) => {
    return {
        type: UPDATE_WORD_REQUEST,
        data,
    }
}

export const deleteWordRequestAction = (data) => {
    return {
        type: DELETE_WORD_REQUEST,
        data
    }
}

const reducer = (state = initialState, action) => produce(state, (draft) => {
    switch (action.type) {
        case LOAD_WORD_REQUEST:
            draft.loadWordLoading = true;
            draft.loadWordDone = false;
            draft.loadWordError = null;
            break;
        case LOAD_WORD_SUCCESS:
            draft.loadWordLoading = false;
            draft.loadWordDone = true;
            draft.word = action.data.data;
            break;
        case LOAD_WORD_FAILURE:
            draft.loadWordLoading = false;
            draft.loadWordDone = false;
            draft.loadWordError = action.data;
            break;
        case LOAD_WORDS_REQUEST:
            draft.loadWordsLoading = true;
            draft.loadWordsDone = false;
            draft.loadWordsError = null;
            break;
        case LOAD_WORDS_SUCCESS:
            draft.loadWordsLoading = false;
            draft.loadWordsDone = true;
            draft.words = action.data.data;
            draft.count = action.data.count[0].count;
            break;
        case LOAD_WORDS_FAILURE:
            draft.loadWordsLoading = false;
            draft.loadWordsDone = false;
            draft.loadWordsError = action.data;
            break;
        case LOAD_WORDS_RANDOM_REQUEST:
            draft.loadWordsRandomLoading = true;
            draft.loadWordsRandomDone = false;
            draft.loadWordsRandomError = null;
            break;
        case LOAD_WORDS_RANDOM_SUCCESS:
            draft.loadWordsRandomLoading = false;
            draft.loadWordsRandomDone = true;
            draft.wordsRandom = action.data.data;
            break;
        case LOAD_WORDS_RANDOM_FAILURE:
            draft.loadWordsRandomLoading = false;
            draft.loadWordsRandomDone = false;
            draft.loadWordsRandomError = action.data;
            break;
        case INSERT_WORD_REQUEST:
            draft.insertWordLoading = true;
            draft.insertWordDone = false;
            draft.insertWordError = null;
            break;
        case INSERT_WORD_SUCCESS:
            draft.insertWordLoading = false;
            draft.insertWordDone = true;
            break;
        case INSERT_WORD_FAILURE:
            draft.insertWordLoading = false;
            draft.insertWordDone = false;
            draft.insertWordError = action.data;
            break;
        case UPDATE_WORD_REQUEST:
            draft.updateWordLoading = true;
            draft.updateWordDone = false;
            draft.updateWordError = null;
            break;
        case UPDATE_WORD_SUCCESS:
            draft.updateWordLoading = false;
            draft.updateWordDone = true;
            break;
        case UPDATE_WORD_FAILURE:
            draft.updateWordLoading = false;
            draft.updateWordDone = false;
            draft.updateWordError = action.data;
            break;
        case DELETE_WORD_REQUEST:
            draft.deleteWordLoading = true;
            draft.deleteWordDone = false;
            draft.deleteWordError = null;
            break;
        case DELETE_WORD_SUCCESS:
            draft.deleteWordLoading = false;
            draft.deleteWordDone = true;
            draft.words = draft.words.filter(i => i.seq !== action.data.seq);
            break;
        case DELETE_WORD_FAILURE:
            draft.deleteWordLoading = false;
            draft.deleteWordDone = false;
            draft.deleteWordError = action.data;
            break;
    }
});

export default reducer;

