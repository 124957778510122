import axios from "axios";
import {
    DELETE_QUESTION_FAILURE,
    DELETE_QUESTION_REQUEST,
    DELETE_QUESTION_SUCCESS,
    INSERT_QUESTION_FAILURE,
    INSERT_QUESTION_REQUEST,
    INSERT_QUESTION_SUCCESS,
    LOAD_QUESTIONS_FAILURE,
    LOAD_QUESTIONS_REQUEST,
    LOAD_QUESTIONS_SUCCESS,
    TRANS_QUESTION_FAILURE,
    TRANS_QUESTION_REQUEST,
    TRANS_QUESTION_SUCCESS,
    UPDATE_QUESTION_FAILURE,
    UPDATE_QUESTION_REQUEST,
    UPDATE_QUESTION_SUCCESS
} from "./constants";
import {all, fork, takeLatest, throttle, call, put} from 'redux-saga/effects';

function loadQuestionsAPI(data) {
    return axios.get(`/vendor/api/v1/question/${data.page}`)
}

function insertQuestionAPI(data) {
    return axios.post('/vendor/api/v1/question', data)
}

function updateQuestionAPI(data) {
    return axios.put('/vendor/api/v1/question', data);
}

function deleteQuestionAPI(data) {
    return axios.delete('/vendor/api/v1/question', {data})
}

function transQuestionAPI(data) {
    return axios.post(`/vendor/api/v1/question/translate`, data);
}

function* loadQuestions(action) {
    try {
        const result = yield call(loadQuestionsAPI, action.data);
        yield put({
            type: LOAD_QUESTIONS_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: LOAD_QUESTIONS_FAILURE,
            data: err.response.data,
        });
    }
}

function* insertQuestion(action) {
    try {
        const result = yield call(insertQuestionAPI, action.data);
        yield put({
            type: INSERT_QUESTION_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: INSERT_QUESTION_FAILURE,
            data: err.response?.data,
        });
    }
}

function* updateQuestion(action) {
    try {
        const result = yield call(updateQuestionAPI, action.data);
        yield put({
            type: UPDATE_QUESTION_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: UPDATE_QUESTION_FAILURE,
            data: err.response.data,
        });
    }
}

function* deleteQuestion(action) {
    try {
        const result = yield call(deleteQuestionAPI, action.data);
        yield put({
            type: DELETE_QUESTION_SUCCESS,
            data: {...result.data, ...action.data},
        });
    } catch (err) {
        yield put({
            type: DELETE_QUESTION_FAILURE,
            data: err.response.data,
        });
    }
}

function* transQuestion(action) {
    try {
        const result = yield call(transQuestionAPI, action.data);
        yield put({
            type: TRANS_QUESTION_SUCCESS,
            data: {...result.data, ...action.data},
        });
    } catch (err) {
        yield put({
            type: TRANS_QUESTION_FAILURE,
            data: err.response.data,
        });
    }
}

function* watchLoadMyPageQuestions() {
    yield takeLatest(LOAD_QUESTIONS_REQUEST, loadQuestions);
}

function* watchInsertQuestion() {
    yield throttle(1000, INSERT_QUESTION_REQUEST, insertQuestion);
}

function* watchUpdateQuestion() {
    yield takeLatest(UPDATE_QUESTION_REQUEST, updateQuestion);
}

function* watchDeleteQuestion() {
    yield throttle(1000, DELETE_QUESTION_REQUEST, deleteQuestion);
}

function* watchTransQuestion() {
    yield throttle(3000, TRANS_QUESTION_REQUEST, transQuestion);
}

export default function* saga() {
    yield all([
        fork(watchLoadMyPageQuestions),
        fork(watchInsertQuestion),
        fork(watchUpdateQuestion),
        fork(watchDeleteQuestion),
        fork(watchTransQuestion),
    ])
}

