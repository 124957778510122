import styled from "styled-components";
import Label from "../../atoms/label";
import {useParams} from "react-router-dom";
import Input from "../../atoms/input";
import {useCallback, useMemo, useRef} from "react";
import JoditEditor from "jodit-react";
import useInput from "../../../hooks/useInput";
import Button from "../../atoms/button";
import {useDispatch} from "react-redux";
import {insertBoardRequestAction} from "../../../redux/board/reducer";
import history from "../../../utils/history";

const BoardAddTemplate = () => {
    const {categorySeq} = useParams();
    const dispatch = useDispatch();
    const [title, onChangeTitle, setTitle] = useInput('');
    const [content, onChangeContent, setContent] = useInput('');

    const editor = useRef(null);
    const config = useMemo(() => ({
        readonly: false,
        width: '100%',
        height: '100%',
        buttons: [
            'bold',
            'italic',
            'underline',
            'fontsize',
            '|',
            'left',
            'center',
            'right',
            'justify',
            '|',
            'undo',
            'redo',
        ],
        removeButtons: ['brush', 'link', 'fullsize', 'image', 'table', 'eraser', 'paragraph', 'copyformat'],
        allowResizeX: false,
        allowResizeY: false,
    }), []);

    const onSubmit = useCallback(() => {
        if (!title || !content) {
            alert('제목과 내용을 입력해주세요.');
            return false;
        }

        dispatch(insertBoardRequestAction({title, content, categorySeq}));
        history.push(`/board/${categorySeq}`);
    }, [title, content, categorySeq]);

    return (
        <BoardWrap>
            <BoardHeaderWrap>
                <Label>{categorySeq === '1' ? 'Q&A' : '학습하기'}</Label>
                <Button shape={'rectangle'} color={'gray-border'} padding={'5px'} onClick={onSubmit}>저장</Button>
            </BoardHeaderWrap>

            <BoardWriteWrap>
                <BoardWriteTitleWrap>
                    <Input type={"text"} theme={'normal'} placeholder={'제목을 입력하세요.'} value={title} onChange={onChangeTitle}></Input>
                </BoardWriteTitleWrap>

                <BoardWriteContentWrap>
                    <JoditEditor
                        ref={editor}
                        value={content}
                        config={config}
                        tabIndex={1}
                        onBlur={newContent => setContent(newContent)}
                    />
                </BoardWriteContentWrap>
            </BoardWriteWrap>
        </BoardWrap>
    )
};

const BoardWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 700px;
  margin: 0 auto;
  padding: 50px 20px;
`

const BoardHeaderWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 2px solid #000;

  & label {
    font-size: 18px;
    font-weight: 800;
  }
`

const BoardWriteWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const BoardWriteTitleWrap = styled.div`
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
`

const BoardWriteContentWrap = styled.div`
    
`

export default BoardAddTemplate;
