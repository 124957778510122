import styled from "styled-components";
import logo from "../../../assets/images/logo.png";
import Button from "../../atoms/button";
import Label from "../../atoms/label";
import history from "../../../utils/history";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useCallback} from "react";
import {logoutRequestAction} from "../../../redux/user/reducer";
import {showModalRequestAction} from "../../../redux/modal/reducer";
import {defaultPrivacy, servicePrivacy} from "../../../constants/privacy";
import useDeviceDetect from "../../../hooks/useDeviceDetect";

const LayoutTemplate = ({children}) => {
    const dispatch = useDispatch();
    const me = useSelector(((state) => state.user.me), shallowEqual);
    const {isMobile} = useDeviceDetect();

    const onLogout = useCallback(() => {
        dispatch(logoutRequestAction());
    }, []);

    const onModal = useCallback((title, content) => {
        dispatch(showModalRequestAction({showModal: true, title, content}));
    }, []);

    return (
        <LayoutWrap>
            <LayoutHeaderWrap>
                <Logo src={logo} alt={'logo'}/>

                <LayoutCategoryWrap>
                    <li className={history.location.pathname === '/' ? 'active' : ''} onClick={() => history.push('/')}>홈</li>
                    <li className={history.location.pathname.indexOf('/introduce') !== -1 ? 'active' : ''} onClick={() => history.push('/introduce')}>프레임캐치 소개</li>
                    {/*<li className={history.location.pathname.indexOf('/learn') !== -1 && 'active'} onClick={() => history.push('/learn')}>학습방법</li>*/}
                    {/*<li className={history.location.pathname.indexOf('/rank') !== -1 && 'active'} onClick={() => history.push('/rank')}>도전랭킹</li>*/}
                    <li className={history.location.pathname.indexOf('/board/1') !== -1 ? 'active' : ''} onClick={() => history.push('/board/1')}>Q&A</li>
                    <li className={history.location.pathname.indexOf('/board/2') !== -1 ? 'active' : ''} onClick={() => history.push('/board/2')}>학습하기</li>
                    <li className={history.location.pathname.indexOf('/board/3') !== -1 ? 'active' : ''} onClick={() => history.push('/board/3')}>안내게시판</li>
                </LayoutCategoryWrap>

                {
                    me.seq ?
                        <LayoutUserWrap>
                            <li onClick={() => history.push('/mypage')}>마이페이지</li>
                            <li onClick={onLogout}>로그아웃</li>
                        </LayoutUserWrap> :
                        <LayoutUserWrap>
                            <li onClick={() => history.push('/login')}>로그인</li>
                            <li onClick={() => history.push('/join')}>회원가입</li>
                            <li onClick={() => history.push('/find')}>ID/PW 찾기</li>
                        </LayoutUserWrap>
                }
            </LayoutHeaderWrap>

            <LayoutContentWrap>
                {children}
            </LayoutContentWrap>

            <LayoutFooterWrap>
                <LayoutFooterTopWrap>
                    <LayoutFooterTopLeftWrap>
                        <LayoutFooterTopLeftTopWrap>
                            <Button onClick={() => onModal('이용약관', servicePrivacy)}>이용약관</Button>
                            <Button onClick={() => onModal('개인정보취급방침', defaultPrivacy)}>개인정보취급방침</Button>
                            <Button onClick={() => history.push('/introduce')}>회사소개</Button>
                        </LayoutFooterTopLeftTopWrap>

                        <LayoutFooterTopLeftBottomWrap>
                            <LayoutFooterTopLeftBottomItem>
                                <Label>프레임캐치 대표 이무석</Label>
                                <Label>사업자번호 : 424-06-02604</Label>
                            </LayoutFooterTopLeftBottomItem>

                            <LayoutFooterTopLeftBottomItem mobile={isMobile}>
                                <Label>주소 : 경북 구미시 고아읍 들성로 9길 20-25</Label>
                                <Label>TEL : 010-5442-1017</Label>
                                <Label>EMAIL : tobe7709@gmail.com</Label>
                            </LayoutFooterTopLeftBottomItem>
                        </LayoutFooterTopLeftBottomWrap>
                    </LayoutFooterTopLeftWrap>

                    <LayoutFooterTopRightWrap>
                        <Logo src={logo} alt={'logo'}/>
                    </LayoutFooterTopRightWrap>
                </LayoutFooterTopWrap>

                <LayoutFooterBottomWrap>
                    <Label>Copyright @ 프레임캐치 All Rights Reserved.</Label>
                </LayoutFooterBottomWrap>
            </LayoutFooterWrap>
        </LayoutWrap>
    )
};

const LayoutWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
`

const Logo = styled.img`
  z-index: 1;
  height: 50px;
  object-fit: cover;
`;

const LayoutHeaderWrap = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  max-width: 1140px;
  padding: 20px 0;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 768px) {
    align-items: start;
    flex-direction: column;
    padding: 15px;
    gap: 20px;
    
    & > img {
      height: 40px;
    }
  }
`

const LayoutContentWrap = styled.div`
  flex: 1;
  background-color: #fbfbfb;
  box-shadow: inset rgb(0 0 0 / 15%) 0 0 6px 0;
`

const LayoutCategoryWrap = styled.ul`
  flex: 1;
  gap: 40px;
  display: flex;
  justify-content: center;

  & > li {
    position: relative;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
  }

  & > li.active {
    font-weight: 700;
  }

  & > li.active:before {
    position: absolute;
    bottom: -32px;
    content: '';
    background-color: #000;
    width: 100%;
    border-radius: 4px;
    height: 4px;
  }

  @media screen and (max-width: 768px) {
    gap: 15px;
    width: 100%;
    white-space: nowrap;
    justify-content: start;

    &::-webkit-scrollbar {
      display: none;
    }

    & > li {
      font-size: 17px;
    }

    & > li.active:before {
      bottom: -16px;
    }
  }
`

const LayoutUserWrap = styled.ul`
  gap: 40px;
  display: flex;
  justify-content: center;

  & > li {
    font-size: 15px;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    position: absolute;
    gap: 10px;
    right: 20px;

    & > li {
      font-size: 13px;
    }
  }
`

const LayoutFooterWrap = styled.div`
  background-color: #0c0c0c;
  color: #fff;
  padding: 3vw;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    padding: 40px 20px;
  }
`

const LayoutFooterTopWrap = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #999;
  padding-bottom: 20px;
  margin-bottom: 20px;

  @media screen and (max-width: 768px) {
    gap: 20px;
    flex-direction: column;
  }
`

const LayoutFooterBottomWrap = styled.div`
  display: flex;
  color : #999;
`

const LayoutFooterTopLeftWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media screen and (max-width: 768px) {
    order: 1;
  }
`

const LayoutFooterTopRightWrap = styled.div`
  @media screen and (max-width: 768px) {
    order: 0;
  }
`

const LayoutFooterTopLeftTopWrap = styled.div`
  display: flex;
  white-space: nowrap;
  gap: 20px;
  
  & Button {
    color : #fff;
    text-align: start;
  }

  @media screen and (max-width: 768px) {
    gap: 10px;
  }
`

const LayoutFooterTopLeftBottomWrap = styled.div`
  display: flex;
  flex-direction: column;
  color : #999;
  white-space: nowrap;
  gap: 10px;
  
  & Button {
    color : #999;
  }

  @media screen and (max-width: 768px) {
    gap: 10px;
  }
`

const LayoutFooterTopLeftBottomItem = styled.div`
  display: flex;
  color : #999;
  white-space: nowrap;
  gap: 20px;
  flex-direction: ${({mobile}) => mobile ? 'column' : 'row' };
  
  & Button {
    color : #999;
  }

  @media screen and (max-width: 768px) {
    gap: 10px;
  }
`

export default LayoutTemplate;
