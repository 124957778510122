import {
    SHOW_MODAL_REQUEST
} from "./constants";
import produce from "immer";

const initialState = {
    showModal: false,
    title: '',
    content: '',
}

export const showModalRequestAction = (data) => {
    return {
        type: SHOW_MODAL_REQUEST,
        data,
    }
}

const reducer = (state = initialState, action) => produce(state, (draft) => {
    switch (action.type) {
        case SHOW_MODAL_REQUEST:
            draft.showModal = action.data.showModal;
            draft.title = action.data.title;
            draft.content = action.data.content;
            break;
    }
});

export default reducer;

