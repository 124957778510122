import LayoutTemplate from "../../components/templates/layout";
import PayTemplate from "../../components/templates/pay";

const Pay = () => {
    return (
        <LayoutTemplate>
            <PayTemplate />
        </LayoutTemplate>
    )
}

export default Pay;
