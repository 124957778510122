import {useEffect, useState} from "react";

export default function useDeviceDetect() {
    const [isMobile, setMobile] = useState(null);

    useEffect(() => {
        // const userAgent = typeof window.navigator === "undefined" ? "" : navigator.userAgent;
        // const mobile = Boolean(
        //     userAgent.match(
        //         /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
        //     )
        // );

        const onResize = () => {
            setMobile(window.innerWidth <= 768);
        }

        window.addEventListener('resize', onResize);
        onResize();
        return () => {
            window.removeEventListener('scroll', onResize);
        };
    }, []);

    return {isMobile};
}
