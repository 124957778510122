import Button from "../../atoms/button";
import Label from "../../atoms/label";
import styled from "styled-components";
import {useCallback, useEffect, useRef, useState} from "react";
import {loadWordsRandomRequestAction} from "../../../redux/word/reducer";
import {updateUserBookmarkRequestAction} from "../../../redux/user/reducer";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import Switch from "../../atoms/switch";
import {insertWordsRememberRequestAction, loadWordsRemembersRequestAction} from "../../../redux/wordsRemember/reducer";

const WordItem = ({type, rememberMode, setRememberMode}) => {
    const dispatch = useDispatch();
    const wordsRandom = useSelector(((state) => state.word.wordsRandom), shallowEqual);
    const wordsRandomLoading = useSelector(((state) => state.word.loadWordsRandomLoading), shallowEqual);
    const wordsRemember = useSelector(((state) => state.wordsRemember.wordsRemember), shallowEqual);
    const me = useSelector(((state) => state.user.me), shallowEqual);
    const [choose, setChoose] = useState([]);

    const onLoadWord = useCallback(() => {
        dispatch(loadWordsRandomRequestAction({type: Array.from(type).join(','), rememberMode}));
    }, [type, rememberMode]);

    useEffect(() => {
        onLoadWord();
    }, [type, rememberMode])

    const onRememberMode = useCallback(() => {
        if (window.confirm('해당 기능을 키거나 끄실 경우 현재 작성 중인 항목들이 모두 초기화됩니다.')) {
            setRememberMode((prevState) => !prevState);
        } else {
            document.getElementById('switch').checked = !document.getElementById('switch').checked;
        }
    }, []);

    useEffect(() => {
        onLoadWord();
    }, [type]);

    const onLoadWordsRemember = useCallback(() => {
        dispatch(loadWordsRemembersRequestAction());
    }, []);

    useEffect(() => {
        onLoadWordsRemember();
    }, []);

    useEffect(() => {
        if (wordsRemember?.words) {
            setChoose(JSON.parse(wordsRemember.words));
        }
    }, [wordsRemember]);

    const onBookmark = useCallback(() => {
        const wordsSeq = wordsRandom.map(i => i.seq);
        dispatch(updateUserBookmarkRequestAction({wordsSeq}));
        alert('표현이 저장되었습니다.');
    }, [wordsRandom]);

    const onWordsRememberRestore = useCallback(() => {
        if (choose.length > 0) {
            dispatch(insertWordsRememberRequestAction({words: JSON.stringify([...choose])}));
            alert('삭제되었습니다.');
            window.location.reload();
        } else {
            alert('선택된 단어가 없습니다.');
        }
    }, [choose]);

    const onWordsRemember = useCallback(() => {
        if (choose.length > 0) {
            dispatch(insertWordsRememberRequestAction({words: JSON.stringify([...choose])}));
            alert('저장되었습니다.');
        } else {
            alert('선택된 단어가 없습니다.');
        }
    }, [choose]);

    const onWordClick = useCallback((word, meaning, type) => {
        const payload = {
            word, meaning, type
        };

        if (choose.find(i => payload.word === i.word)) {
            setChoose([...choose.filter(i => payload.word !== i.word)]);
        } else {
            choose.push(payload)
            setChoose([...choose]);
        }
    }, [choose, rememberMode]);

    return (
        <HomeDayWrap>
            <HomeDayTopWrap>
                {
                    me.seq && <Switch id={'switch'} onClick={onRememberMode} />
                }

                <Button onClick={onLoadWord} display={'flex'}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#23C462" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5"
                         strokeLinecap="round" strokeLinejoin="round">
                        <path
                            d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"/>
                    </svg>
                </Button>
            </HomeDayTopWrap>

            <Label>단어리스트</Label>

            {
                me.seq &&
                <HomeDayTodayQuestionCountWrap>
                    <Label>일일누적 <strong> {me.todayQuestionCount} </strong></Label>
                </HomeDayTodayQuestionCountWrap>
            }

            <HomeDayButtonWrap>
                {
                    me.seq ?
                        rememberMode ?
                            <Button shape={'rectangle'} color={'normal-border'} padding={'5px'} onClick={onWordsRememberRestore}>다시 학습하기</Button> :
                            <Button shape={'rectangle'} color={'normal-border'} padding={'5px'} onClick={onWordsRemember}>표현저장</Button>
                        : ''
                }
            </HomeDayButtonWrap>

            <HomeDayListWrap>
                {
                    wordsRandom && wordsRandom.map((i, index) => <Label key={index} onClick={() => onWordClick(i.word, i.meaning, i.type)} className={!wordsRandomLoading && rememberMode ? !choose.find(j => i.word === j.word) && 'choose' : choose.find(j => i.word === j.word) && 'choose'}>{i.word} {i.meaning}</Label>)
                }
            </HomeDayListWrap>
        </HomeDayWrap>
    )
}

const HomeDayWrap = styled.div`
  position: relative;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #eee;
  gap: 10px;
  display: flex;
  flex-direction: column;
  min-width: 250px;
  //width: 260px;

  & > label {
    font-size: 16px;
    font-weight: 500;
  }

  & strong {
    font-size: 16px;
    font-weight: 500;
    color: #23C462
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const HomeDayTopWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  top: 10px;
  right: 10px;
`

const HomeDayTodayQuestionCountWrap = styled.div`
  & label {
    display: flex;
    gap: 10px;
    font-size: 15px;
    font-weight: 500;
  }
`

const HomeDayButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;

  & button {
    flex: 1;
    font-size: 13px;
  }
`

const HomeDayListWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  
  & label {
    cursor: pointer;
  }
  
  & label.choose {
    background-color: yellow;
  }
`

export default WordItem;
