import LayoutTemplate from "../../components/templates/layout";
import RankTemplate from "../../components/templates/rank";

const Rank = () => {
    return (
        <LayoutTemplate>
            <RankTemplate />
        </LayoutTemplate>
    )
}

export default Rank;
