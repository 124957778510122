import LayoutTemplate from "../../components/templates/layout";
import FindPwTemplate from "../../components/templates/find/pw";

const FindPw = () => {
    return (
        <LayoutTemplate>
            <FindPwTemplate />
        </LayoutTemplate>
    )
}

export default FindPw;
