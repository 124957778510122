import {unstable_HistoryRouter as HistoryRouter, Routes, Route} from "react-router-dom";
import history from "../../utils/history";
import {useDispatch} from "react-redux";
import "../../assets/css/common.css";
import {useCallback, useEffect} from "react";
import {loginCheckRequestAction} from "../../redux/user/reducer";
import Home from "../home";
import Introduce from "../introduce";
import Learn from "../learn";
import Rank from "../rank";
import Login from "../login";
import Join from "../join";
import Find from "../find";
import MyPage from "../mypage";
import FindId from "../find/id";
import FindPw from "../find/pw";
import Board from "../board";
import BoardAdd from "../board/add";
import Pay from "../pay";

const App = () => {
    const dispatch = useDispatch();
    const loginCheck = useCallback(() => {
        dispatch(loginCheckRequestAction());
    }, []);

    history.listen(() => {
        loginCheck();
    });

    useEffect(() => {
        loginCheck();
    }, []);

    return (
        <HistoryRouter history={history}>
            <Routes>
                <Route path="/" element={<Home />}/>
                <Route path="/introduce" element={<Introduce />}/>
                <Route path="/learn" element={<Learn />}/>
                <Route path="/rank" element={<Rank />}/>
                <Route path="/board/:categorySeq" element={<Board />}/>
                <Route path="/board/write/:categorySeq" element={<BoardAdd />}/>
                <Route path="/login" element={<Login />}/>
                <Route path="/join" element={<Join />}/>
                <Route path="/find" element={<Find />}/>
                <Route path="/find/id" element={<FindId />}/>
                <Route path="/find/pw" element={<FindPw />}/>
                <Route path="/mypage" element={<MyPage />}/>
                <Route path="/pay" element={<Pay />}/>
            </Routes>
        </HistoryRouter>
    );
}

export default App;
