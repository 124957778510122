import axios from "axios";
import {
    DELETE_BOARD_FAILURE,
    DELETE_BOARD_REQUEST,
    DELETE_BOARD_SUCCESS,
    INSERT_BOARD_FAILURE,
    INSERT_BOARD_REQUEST,
    INSERT_BOARD_SUCCESS,
    LOAD_BOARD_FAILURE, LOAD_BOARD_REQUEST,
    LOAD_BOARD_SUCCESS,
    LOAD_BOARDS_FAILURE,
    LOAD_BOARDS_REQUEST,
    LOAD_BOARDS_SUCCESS,
    UPDATE_BOARD_FAILURE, UPDATE_BOARD_REQUEST, UPDATE_BOARD_SUCCESS
} from "./constants";
import {all, fork, takeLatest, call, put} from 'redux-saga/effects';
import history from "../../utils/history";

function loadBoardsAPI(data) {
    return axios.get(`/vendor/api/v1/board/${data.categorySeq}`)
}

function loadBoardAPI(data) {
    return axios.get(`/vendor/api/v1/board/detail/${data.seq}`)
}

function insertBoardAPI(data) {
    return axios.post('/vendor/api/v1/board', data)
}

function updateBoardAPI(data) {
    return axios.put('/vendor/api/v1/board', data);
}

function deleteBoardAPI(data) {
    return axios.delete('/vendor/api/v1/board', {data})
}

function* loadBoards(action) {
    try {
        const result = yield call(loadBoardsAPI, action.data);
        yield put({
            type: LOAD_BOARDS_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: LOAD_BOARDS_FAILURE,
            data: err.response.data,
        });
    }
}

function* loadBoard(action) {
    try {
        const result = yield call(loadBoardAPI, action.data);
        yield put({
            type: LOAD_BOARD_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: LOAD_BOARD_FAILURE,
            data: err.response.data,
        });
    }
}

function* insertBoard(action) {
    try {
        const result = yield call(insertBoardAPI, action.data);
        yield put({
            type: INSERT_BOARD_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: INSERT_BOARD_FAILURE,
            data: err.response.data,
        });
        alert(err.response.data.data.message);
    }
}

function* updateBoard(action) {
    try {
        const result = yield call(updateBoardAPI, action.data);
        yield put({
            type: UPDATE_BOARD_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: UPDATE_BOARD_FAILURE,
            data: err.response.data,
        });
    }
}

function* deleteBoard(action) {
    try {
        const result = yield call(deleteBoardAPI, action.data);
        yield put({
            type: DELETE_BOARD_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: DELETE_BOARD_FAILURE,
            data: err.response.data,
        });
    }
}

function* watchLoadBoards() {
    yield takeLatest(LOAD_BOARDS_REQUEST, loadBoards);
}

function* watchLoadBoard() {
    yield takeLatest(LOAD_BOARD_REQUEST, loadBoard);
}

function* watchInsertBoard() {
    yield takeLatest(INSERT_BOARD_REQUEST, insertBoard);
}

function* watchUpdateBoard() {
    yield takeLatest(UPDATE_BOARD_REQUEST, updateBoard);
}

function* watchDeleteBoard() {
    yield takeLatest(DELETE_BOARD_REQUEST, deleteBoard);
}

export default function* saga() {
    yield all([
        fork(watchLoadBoards),
        fork(watchLoadBoard),
        fork(watchInsertBoard),
        fork(watchUpdateBoard),
        fork(watchDeleteBoard),
    ])
}

