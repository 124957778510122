import {combineReducers} from "redux";
import user from "./redux/user/reducer";
import modal from "./redux/modal/reducer";
import word from "./redux/word/reducer";
import wordsRemember from "./redux/wordsRemember/reducer";
import board from "./redux/board/reducer";
import question from "./redux/question/reducer";
import type from "./redux/type/reducer";

const rootReducer = (state, action) => {
    switch (action.type) {
        default: {
            const combinedReducer = combineReducers({
                user,
                modal,
                word,
                wordsRemember,
                board,
                question,
                type,
            });
            return combinedReducer(state, action);
        }
    }
};

export default rootReducer;
