import Label from "../../atoms/label";
import Button from "../../atoms/button";
import styled from "styled-components";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useState} from "react";
import {loadUsersRankAllMeRequestAction, loadUsersRankRequestAction} from "../../../redux/user/reducer";

const RankItem = () => {
    const dispatch = useDispatch();
    const me = useSelector(((state) => state.user.me), shallowEqual);
    const rank = useSelector(((state) => state.user.rank), shallowEqual);
    const userRank = useSelector(((state) => state.user.userRank), shallowEqual);
    const [rankFilter, setRankFilter] = useState('day');

    const onLoadRank = useCallback(() => {
        dispatch(loadUsersRankRequestAction());
    }, []);

    useEffect(() => {
        onLoadRank();
    }, []);

    const onRank = useCallback(() => {
        dispatch(loadUsersRankAllMeRequestAction());
    }, []);

    return (
        <RankWrap>
            <Button onClick={onLoadRank}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="#23C462" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"/>
                </svg>
            </Button>

            <Label> 습관랭킹리스트 (1~50위) </Label>

            {
                me.seq &&
                <MyRankWrap>
                    <Label>나의 주간 랭킹 <strong> {userRank}</strong></Label>
                    <Button shape={'oval'} color={'normal-border'} padding={'3px 12px'} onClick={onRank}>확인</Button>
                </MyRankWrap>
            }

            <RankFilterWrap>
                <Button className={rankFilter === 'day' && 'active'} onClick={() => setRankFilter('day')}>일일</Button>
                <Button className={rankFilter === 'week' && 'active'} onClick={() => setRankFilter('week')}>주간</Button>
                <Button className={rankFilter === 'month' && 'active'} onClick={() => setRankFilter('month')}>월간</Button>
                <Button className={rankFilter === 'year' && 'active'} onClick={() => setRankFilter('year')}>연간</Button>
            </RankFilterWrap>

            <RankListWrap>
                <RankListHeaderWrap>
                    <Label>랭킹</Label>
                    <Label>아이디</Label>
                    <Label>카운트</Label>
                </RankListHeaderWrap>

                <RankListContentWrap>
                {
                    rank && rank[rankFilter] && rank[rankFilter].map((i, index) =>
                        <RankListItemWrap key={index} className={i.name === me.name ? 'active' : ''}>
                            <Label>{i.index ? i.index : index + 1}</Label>
                            <Label>{i.name}</Label>
                            <Label>{i.count < 1 ? '-' : i.count}</Label>
                        </RankListItemWrap>
                    )
                }
                </RankListContentWrap>
            </RankListWrap>
        </RankWrap>
    )
}


const RankWrap = styled.div`
  position: relative;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #eee;
  gap: 10px;
  display: flex;
  flex-direction: column;
  min-height: 500px;
  min-width: 250px;
  //width: 250px;

  & > button {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  & > label {
    font-size: 16px;
    font-weight: 500;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const MyRankWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > label {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    font-size: 15px;
    font-weight: 500;
  }

  & strong {
    font-size: 16px;
    font-weight: 500;
    color: #23C462
  }

  & button {
    font-size: 11px;
  }
`

const RankFilterWrap = styled.div`
  display: flex;
  gap: 10px;

  & > button {
    flex: 1;
    font-size: 12px;
    padding: 8px 10px;
    background-color: #F7F8FA;
    color: #999;
    border-radius: 8px;
  }

  & > button.active {
    background-color: #23C462;
    color: #fff;
  }
`

const RankListWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const RankListHeaderWrap = styled.div`
  display: flex;
  font-weight: 700;
  padding: 5px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  text-align: center;

  & label:nth-child(1) {
    width: 20%;
  }

  & label:nth-child(2) {
    flex: 1;
  }

  & label:nth-child(3) {
    width: 20%;
  }
`

const RankListItemWrap = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px;
  gap: 10px;
  color: #666;
  text-align: center;
  
  &.active {
    background-color: #FFECB2;
  }

  & label:nth-child(1) {
    width: 20%;
  }

  & label:nth-child(2) {
    flex: 1;
  }

  & label:nth-child(3) {
    width: 20%;
  }
`

const RankListContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 370px;
  overflow-x: auto;
`

export default RankItem;
