import styled from "styled-components";
import Checkbox from "../../atoms/checkbox";

const MixCheckbox = ({children, ...props}) => {
    return (
        <Wrap>
            <MixCheckboxLabelWrap>
                <Checkbox {...props} />
                {children}
            </MixCheckboxLabelWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
  display: flex;
  justify-content: end;
`

const MixCheckboxLabelWrap = styled.label`
  cursor: pointer;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  user-select: none;
`

export default MixCheckbox;