import LayoutTemplate from "../../components/templates/layout";
import FindTemplate from "../../components/templates/find";

const Find = () => {
    return (
        <LayoutTemplate>
            <FindTemplate />
        </LayoutTemplate>
    )
}

export default Find;
