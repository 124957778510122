import LayoutTemplate from "../../components/templates/layout";
import LearnTemplate from "../../components/templates/learn";

const Learn = () => {
    return (
        <LayoutTemplate>
            <LearnTemplate />
        </LayoutTemplate>
    )
}

export default Learn;
