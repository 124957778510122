import LayoutTemplate from "../../components/templates/layout";
import LoginTemplate from "../../components/templates/login";

const Login = () => {
    return (
        <LayoutTemplate>
            <LoginTemplate />
        </LayoutTemplate>
    )
}

export default Login;
