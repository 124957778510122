import styled from "styled-components";

const Input = ({...props}) => {
    return (
        <SizeInputWrap {...props} />
    )
}

const InputWrap = styled.input`
  margin: ${({margin}) => margin};
`;

const ThemeInputWrap = styled(InputWrap)`
  ${({theme}) => {
    switch (theme) {
      case 'normal':
        return `
            width: 100%;
            padding: 8px;
            border: 1px solid #eee;
            border-radius: 6px;
            
            &:focus {
              border: 1px solid #23C462;
              transition: border 0.2s;
            }
        `;
      default:
        return;
    }
  }};
`

const SizeInputWrap = styled(ThemeInputWrap)`
  ${({size}) => {
  switch (size) {
    case 'xs':
      return `
            height: 35px;
        `;
    case 'sm':
      return `
            height: 45px;
        `;
    case 'md':
      return `
            height: 60px;
        `;
    default:
      return;
  }
}};
`

export default Input;
