import LayoutTemplate from "../../components/templates/layout";
import FindIdTemplate from "../../components/templates/find/id";

const FindId = () => {
    return (
        <LayoutTemplate>
            <FindIdTemplate />
        </LayoutTemplate>
    )
}

export default FindId;
