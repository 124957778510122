import styled from "styled-components";

const Button = ({children, ...props}) => {
    return (
        <ShapeButtonWrap {...props}>
            {
                props.loading ? <Spinner /> : children
            }
        </ShapeButtonWrap>
    )
};

const ButtonWrap = styled.button`
  display: ${({display}) => display};
  align-items: ${({alignItems}) => alignItems};
  justify-content: ${({justifyContent}) => justifyContent};
  max-width: ${({maxWidth}) => maxWidth};
  max-height: ${({maxHeight}) => maxHeight};
  padding: ${({padding}) => padding};
  margin: ${({margin}) => margin};
  float: ${({float}) => float};
  font-size: ${({fontSize}) => fontSize};
  text-align: ${({textAlign}) => textAlign};
  height: ${({size}) => {
    switch (size) {
      case 'small':
        return '35px';
      case 'middle':
        return '45px';
      case 'full':
        return '100%';
    }
  }};
`;

const ThemeButtonWrap = styled(ButtonWrap)`
  ${({color}) => {
    switch (color) {
      case 'gray':
        return `
            background-color: #B7B7BF;
            color: #fff;
        `;
      case 'gray-border':
        return `
            border: 1px solid #999;
            color: #999;
        `;
      case 'navy':
        return `
            background-color: #4169E1FF;
            color: #fff;
        `;
      case 'white':
        return `
            background-color: #fff;
            color: #000;
            border: 1px solid #eee;
            font-weight: 400;
            padding: 10px;
        `;
      case 'normal':
        return `
            background-color: #23C462;
            color: #fff;
            font-weight: 700;
        `;
      case 'normal-border':
        return `
            background-color: #fff;
            border: 1px solid #23C462;
            color: #23C462;
            font-weight: 700;
        `;
      default:
        return;
    }
  }};
`

const ShapeButtonWrap = styled(ThemeButtonWrap)`
  ${({shape}) => {
    switch (shape) {
      case 'rectangle':
        return `border-radius : 6px;`;
      case 'oval':
        return `border-radius : 23px;`;
      case 'circle':
        return `border-radius : 100%;`;
      default:
        return;
    }
  }};
`

const Spinner = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #ffffff;
  position: relative;
  animation: spinner 1.4s infinite linear;
  background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  transform: translateZ(0);
  margin: 0 auto;
  
  &:before {
    width: 50%;
    height: 50%;
    background: #fff;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }
  
  &:after {
    background: #23C462;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`



export default Button;
