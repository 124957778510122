import styled from "styled-components";
import Label from "../../atoms/label";
import Input from "../../atoms/input";
import Button from "../../atoms/button";

const FindPwTemplate = () => {
    return (
        <FindPwWrap>
            <Label fontWeight={'bold'}> PW 찾기 </Label>

            <Input type={"email"} theme={'normal'} size={'sm'}  placeholder={'이메일'}/>
            <Input type={"text"} theme={'normal'} size={'sm'}  placeholder={'코드'}/>
            <Button shape={'rectangle'} size={'middle'} color={'normal'}>확인</Button>
        </FindPwWrap>
    )
};

const FindPwWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 400px;
  margin: 0 auto;
  padding: 50px 20px;

  & button {
    margin-top: 10px;
  }
`

export default FindPwTemplate;
