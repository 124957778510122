import styled from "styled-components";
import Label from "../../atoms/label";
import {shallowEqual, useSelector} from "react-redux";
import {useState} from "react";
import Button from "../../atoms/button";
import history from "../../../utils/history";
import {useParams} from "react-router-dom";

const BoardTemplate = () => {
    const {categorySeq} = useParams();
    const me = useSelector(((state) => state.user.me), shallowEqual);
    const boards = useSelector(((state) => state.board.boards), shallowEqual);
    const [target, setTarget] = useState('');

    return (
        <BoardWrap>
            <BoardHeaderWrap>
                <Label>
                    {
                        categorySeq === '1' ? 'Q&A' : categorySeq === '2' ? '학습하기' : '안내게시판'
                    }
                </Label>

                {
                    categorySeq === '1' && me.seq &&
                    <Button shape={'rectangle'} color={'gray-border'} padding={'5px'}
                            onClick={() => history.push(`/board/write/${categorySeq}`)}>글쓰기</Button>
                }
            </BoardHeaderWrap>

            <BoardListWrap>
                {
                    boards.map((i, index) => {
                        return (
                            <BoardListItem className={target === index ? 'active' : ''} key={index}
                                           onClick={() => setTarget(index === target ? '' : index)}>
                                <BoardListItemHeader>
                                    <Label>{index + 1}</Label>
                                    <Label>{i.title}</Label>
                                </BoardListItemHeader>
                                <BoardListItemContentWrap display={target === index}>
                                    <BoardListItemContentInfo>
                                        <Label>{i.insertName} {i.insertDate}</Label>
                                    </BoardListItemContentInfo>

                                    <BoardListItemContent dangerouslySetInnerHTML={{__html: i.content}}></BoardListItemContent>
                                </BoardListItemContentWrap>

                                {
                                    target === index && i.answer &&
                                    <BoardListItemAnswer dangerouslySetInnerHTML={{__html: i.answer}}></BoardListItemAnswer>
                                }
                            </BoardListItem>
                        )
                    })
                }
            </BoardListWrap>
        </BoardWrap>
    )
};

const BoardWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 700px;
  margin: 0 auto;
  padding: 50px 20px;
`

const BoardHeaderWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & label {
    font-size: 18px;
    font-weight: 800;
  }
`

const BoardListWrap = styled.div`
  background-color: #fff;
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
  padding: 0 20px;
`

const BoardListItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  border-bottom: 1px solid #eee;
  cursor: pointer;

  &.active > div > label {
    font-weight: 700;
  }
`

const BoardListItemHeader = styled.div`
  display: flex;
  gap: 20px;

  & > label {
    font-size: 18px;
  }

  @media screen and (max-width: 768px) {
    & > label {
      font-size: 18px;
    }
  }
`

const BoardListItemContentWrap = styled.div`
  padding: 0 20px;
  display: ${({display}) => display ? 'block' : 'none'}
`

const BoardListItemContent = styled.div`
  padding: 20px 0;
`

const BoardListItemContentInfo = styled.div`
  display: flex;
  justify-content: end;
`

const BoardListItemAnswer = styled.div`
  padding: 20px 20px 0 20px;
  border-top: 1px solid #000;
  display: block;
`

export default BoardTemplate;
