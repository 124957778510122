export const servicePrivacy = `
<p>프레임캐치 (FrameCatch) 이용약관</p>
<p>본 약관은 2023년 02월 01일부터 시행합니다.</p>
<br/>
<p>■ 제 1조 (명칭) 프레임캐치 (FrameCatch)</p>
<br/>
<p>■ 제 2조(URL) http://framecatch.co.kr</p>
<br/>
<p>■ 제 3조 (목적)</p>
<p>영어를 쉽게 배우고 습관화 할 수 있는 영작사이트입니다. 언제 어디서나 영어 문장을 적고 다른 사용자들과 랭킹으로 경쟁하며 꾸준히 동기부여 받으며 학습을 지속하게 도와주자는 목 적을 가지고 있습니다.</p>
<br/>
<p>■ 제 4조 (회원이나 지역의 범위)</p>
<p>한국 뿐만 아니라 전 세계 모든 사람이 이용할 수 있는 서비스입니다. ■ 제 5조 (정의)</p>
<p>1. FrameCatch – 웹사이트 프레임캐치입니다.</p>
<p>2. 이용자 - 모든 서비스 사이트에 접속하거나 방문하여 이 약관에 따라 제공하는 모든 서비 스를 받는 정회원, 회원 및 비회원을 말합니다.</p>
<p>3. 정회원 - 약정금액을 납부한 회원으로서 금액에 해당하는 기간 동안 프레임캐치가 제공하 는 모든 서비스를 받는 경우</p>
<p>4, 회원 - 가입하였으나 정회원이 아닌 경우, 또는 정회원에 대한 기간이 만료된 경우로 프레 임캐치가 제공하는 서비스의 일부를 받는 경우</p>
<p>5. 비회원 - 회원에 가입하지 않고 프레임캐치가 제공하는 서비스를 이용하는 자로 서비스의 일부가 제한적으로 제공됩니다.</p>
<p>5. 아이디(ID) - 회원의 식별과 서비스 이용을 위해 관리자가 승인하는 문자와 숫자의 조합을 말합니다.</p>
<br/>
<p>■ 제 6조 (약관의 효력과 변경)</p>
<p>1. 약관은 회원이 신규 가입 시 "약관에 동의합니다"라는 물음에 회원이 "동의" 버튼을 클릭하 는 것으로 효력이 발생됩니다.</p>
<p>2. 합리적인 사유가 발생될 경우에는 이 약관을 변경할 수 있으며, 약관을 변경할 경우 FrameCatch는 지체 없이 이를 공지합니다.</p>
<p>3. 회원은 변경된 약관에 동의하지 않을 경우, 서비스 이용을 중단하고 탈퇴할 수 있습니다. 약관이 변경된 이후에도 계속적으로 서비스를 이용하는 경우에는 회원이 약관의 변경 사항에</p>
<p>동의한 것으로 봅니다.</p>
<br/>
<p>■ 제 7조 (이용계약)</p>
<p>서비스 이용계약은 회원이 FrameCatch가 정한 약관에 동의하고, FrameCatch에 가입 및 서 비스 이용을 신청하며, FrameCatch가 이를 허락하는 것으로 이루어집니다. 정회원의 경우에 는 약정금액을 납부하는 즉시 정회원 자격이 부여되며 FrameCatch의 모든 자료를 이용하실 수 있습니다. 어떠한 경우에도 약정금액의 환불은 불가하오니, 정회원으로 신청시 신중을 기 하시기 바랍니다.</p>
<br/>
<p>■ 제 8조 (이용신청의 승낙)</p>
<p>1. FrameCatch는 회원이 모든 사항을 정확히 기재하여 신청할 경우 서비스 이용을 승낙합니 다. 단, 아래의 경우는 승낙을 거부하거나 취소할 수 있습니다.</p>
<p>a. 이용신청 시 필요내용을 허위로 기재하여 신청한 경우</p>
<p>b. 사회의 안녕 질서 또는 미풍양속을 저해할 목적으로 신청한 경우</p>
<p>c. 영리를 추구할 목적으로 본 서비스를 이용하고자 하는 경우</p>
<p>d. 기타 FrameCatch이 정한 이용신청 요건에 맞지 않을 경우</p>
<p>2. FrameCatch은 기술적인 이유나 정책 등의 이유로 인해 이용신청의 승낙을 보류할 수 있 습니다.</p>
<p>3. 회원이 입력하는 정보는 아래와 같으며, 이외에도 FrameCatch는 회원에게 추가정보의 입 력을 요구할 수 있습니다: 아이디, 비밀번호, 이름 또는 닉네님, 이메일 등</p>
<br/>
<p>■ 제 9조 (계약 사항의 변경)</p>
<p>회원은 서비스이용 신청 시 기재한 사항이 변경되었을 경우, 수정하여야 합니다. ■ 제 10조 (개인정보의 이용)</p>
<p>1. FrameCatch는 회원이 회원가입시 제출한 개인정보를 보유할 수 있습니다.</p>
<p>2. FrameCatch는 서비스 제공으로 알게 된 회원의 신상정보를 본인의 승낙 없이 제3자에게 누설, 배포하지 않습니다.</p>
<p>3. FrameCatch는 회원의 전체 또는 일부 정보를 업무와 관련 된 통계 자료로 사용할 수 있 습니다.</p>
<br/>
<p>■ 제 11조 (계약 해지)</p>
<p>회원이 서비스 이용 계약을 해지하고자 할 때는 FrameCatch 운영자에게 메일로 그 내용을 통보하면 됩니다. 이때 FrameCatch는 특별한 사유가 없는 한 회원의 마이코엔 탈퇴를 즉시 처리합니다.</p>
<br/>
<p>■ 제 12조 (탈퇴 회원의 개인정보 이용)</p>
<p>FrameCatch는 회원이 탈퇴하면 회원 ID와 이름을 회원정보를 회원이 탈퇴하는 시점에 탈퇴 회원정보를 재생이나 복원이 불가능하도록 데이터에서 완전히 삭제합니다.</p>
<br/>
<p>■ 제 13조 (자격상실)</p>
<p>다음 각항의 사유에 해당하는 경우 FrameCatch는 사전 통보 없이 이용계약을 해지하거나 기 간을 정하여 서비스 이용을 중지할 수 있습니다.</p>
<p>1. 회원정보를 누락시킨 경우</p>
<p>2. 가입신청 시 허위 내용으로 등록한 경우</p>
<p>3. 타인의 아이디와 비밀번호 도용한 경우</p>
<p>4. FrameCatch, 다른 회원 또는 제3자의 지적재산권을 침해하는 경우</p>
<p>5. 사회의 안녕과 질서, 미풍양속을 해치는 행위를 하는 경우</p>
<p>6. 타인의 명예를 손상시키거나 불이익을 주는 행위를 한 경우</p>
<p>7. 정보통신망에 장애를 일으킬 수 있는 행위를 하는 경우</p>
<p>8. FrameCatch의 허락 없이 FrameCatch의 서비스를 이용해 영리행위를 하는 경우</p>
<br/>
<p>■ 제 14조 (서비스의 제공)</p>
<p>1. FrameCatch는 이 약관에서 정한 바에 따라 지속적이고 안정적인 서비스의 제공을 위해 노력합니다.</p>
<p>2. FrameCatch는 서비스에 장애가 발생할 경우 그 복구에 최선을 다합니다. 다만 시스템점 검, 천재지변, 비상사태, 기타 부득이한 경우에는 서비스를 일시적으로 중단할 수 있습니다.</p>
<p>3. FrameCatch는 이용계약의 체결, 계약사항의 변경, 계약해지 등 회원과의 계약사항의 처 리에 있어 회원에게 편의를 제공하도록 노력합니다.</p>
<br/>
<p>■ 제 15조 (정보의 제공)</p>
<p>FrameCatch는 회원약관, 개인정보 보호 정책, 기타 중대한 영업정책 의 변경 등 회원이 반 드시 알고 있어야 하는 공지사항이 있는 경우 공지메일을 발송할 수 있습니다.</p>
<br/>
<p>■ 제 16조 (회원의 게시물)</p>
<p>1. 게시물이라 함은 FrameCatch의 각종 게시판에 회원이 게시한 게시물, 질문, 답변, 자료를 모두 총칭합니다.</p>
<p>2. FrameCatch는 게시물의 서비스 내 게시권을 갖습니다.</p>
<p>3. 회원이 게시하는 게시물 및 정보로 인해 발생하는 손실이나 문제는 회원 개인의 책임이며, FrameCatch는 이에 대하여 책임을 지지 않습니다. 단 FrameCatch의 고의에 의한 경우에는 그렇지 않습니다.</p>
<p>4. 회원의 게시물로 인하여 제3자의 FrameCatch에 대한 청구, 소송, 기타 일체의 분쟁이 발</p>
<p>생한 경우 회원은 그 해결에 소요되는 비용을 부담하고 FrameCatch를 위하여 분쟁을 처리하 여야 하며, FrameCatch가 제3자에게 배상하거나 FrameCatch에 손해가 발생한 경우 회원은 FrameCatch에 배상하여야 합니다.</p>
<p>5. FrameCatch는 회원의 게시물이 다음 각 항에 해당되는 경우에는 사전통지 없이 삭제 합 니다.</p>
<p>a. 제3자를 비방하거나 중상 모략하여 명예를 손상시키는 경우</p>
<p>b. 공공질서, 미풍양속에 저해되는 내용인 경우</p>
<p>c. FrameCatch의 저작권, 제3자의 저작권등 기타 권리를 침해하는 내용인 경우</p>
<p>d. 상업성이 있는 게시물이나 상업광고를 게시한 경우</p>
<p>e. 사이트의 개설취지에 맞지 않을 경우</p>
<p>f. 기타 관계법령을 위반한다고 판단되는 경우</p>
<p>6. 5항의 원인으로 발생한 각종 손해 및 관계기관에 의한 법적 조치에 따르는 책임은 회원 본 인이 집니다.</p>
<p>7. FrameCatch는 5항의 이유로 삭제한 게시물의 보관의무를 갖지 않습니다.</p>
<p>8. FrameCatch는 모든 게시물을 검사 또는 검열할 의무를 갖지 않습니다.</p>
<br/>
<p>■ 제 17조 (회원 게시물의 이용)</p>
<p>1. 회원이 서비스 화면 내에 게시한 게시물의 저작권은 게시한 회원에게 귀속됩니다. 또한 게 시자의 동의 없이 게시물을 상업적으로 이용할 수 없습니다. 다만 비영리 목적인 경우는 그러 하지 아니하며, 또한 서비스 내의 게재권을 갖습니다.</p>
<p>2. 회원과 FrameCatch는 서비스를 이용하여 취득한 정보를 임의 가공, 판매하는 행위 등 서 비스에 게재된 자료를 상업적으로 사용할 수 없습니다.</p>
<p>3. FrameCatch는 회원이 본 서비스를 통하여 게시, 게재, 전자메일로 전송한 정보의 정확성 및 신뢰도 등 내용에 대해 책임을 지지 않으며, 회원의 게시물로 인하여 발생하는 민, 형사상 의 책임은 전적으로 회원이 부담하여야 합니다. FrameCatch는 회원이 본 서비스를 통하여 게시, 게재, 전자메일 또는 달리 전송한 정보의 정확성 및 신뢰도 등 내용에 대해 책임을 지 지 않으며, 회원의 게시물로 인하여 발생하는 민,형사상의 책임은 전적으로 회원이 부담하여 야 합니다.</p>
<br/>
<p>■ 제 18조 (서비스 이용 시간)</p>
<p>1. FrameCatch의 업무 혹은 기술적 이유에 의한 별도의 공지가 없는 한, 서비스는 연중무휴 1일 24시간 동안 계속 제공됩니다.</p>
<p>2. FrameCatch가 업무 혹은 기술적 이유로 서비스를 중단하고자 할 경우에는 사전에 서비스 중단사유 및 시간을 공지해야 합니다. 단, 천재지변, 긴급사태, 시스템 장애 등 예기치 않은 상황의 경우는 사후에 공지할 수 있습니다.</p>
<br/>
<p>■ 제 19조 (손해배상)</p>
<p>FrameCatch는 서비스 이용과 관련하여 FrameCatch의 고의 또는 중과실이 없는 한 회원에</p>
<p>게 발생한 어떠한 손해에 대해서도 배상 및 보상의 책임을 지지 않습니다. ■ 제 20조 (면책)</p>
<p>1. FrameCatch는 천재지변, 비상사태 또는 이에 준하는 불가피한 사정으로 서비스를 중단할 경우, 서비스 중단으로 회원에게 발생되는 문제에 대한 책임이 면책됩니다.</p>
<p>2. FrameCatch는 회원의 귀책사유로 인한 서비스 이용의 장애에 대해 책임을 지지 않습니 다.</p>
<p>3. FrameCatch는 서비스를 통해 제공된 정보, 제품, 서비스, 소프트웨어, 그래픽, 음성, 동영 상의 적합성, 정확성, 시의성, 신빙성에 관한 보증 또는 담보책임을 지지 않습니다.</p>
<p>4. FrameCatch는 회원이 게시한 게시물의 적합성, 정확성, 시의성, 신빙성에 관한 보증 또는 담보 책임을 지지 않습니다.</p>
<p>5. 회원이 FrameCatch가 제공한 서비스를 통해 취득한 정보나 서비스에 게시된 다른 회원의 게시물을 통해 법률적 또는 의학적, 기타 재정적 사항에 관하여 필요한 결정을 하고자 할 때 에는 반드시 사전에 전문가와 상의하기를 권고합니다.</p>
<br/>
<p>■ 제 21조 (분쟁해결)</p>
<p>본 약관의 해석과 적용 및 본 약관과 관련한 분쟁의 해결에는 대한민국 법률이 적용됩니다.</p>
<br/>
<p>■ 제 22조 (약관의 변경)</p>
<p>FrameCatch는 본 약관을 사전 고지 없이 변경할 수 있으며, 변경된 약관은 웹사이트에 공지 함으로써 가입자가 직접 확인 하도록 할 것입니다. 가입자가 변경된 약관에 동의하지 아니하 는 경우, 가입자는 본인의 회원등록을 취소할 수 있으며, 계속 사용의 경우는 약관 변경에 대 한 동의로 간주됩니다. 변경된 약관은 공지와 동시에 그 효력이 발생됩니다.</p>
`;

export const defaultPrivacy = `
<p>프레임캐치 사이트(이하 프레임캐치)는 고객님의 개인정보를 중요시하며, "정보통신망 이용촉진 및 정보보호"에 관한 법률을 준수하고 있습니다.</p>
<br/>
<p>"프레임캐치"는 개인정보취급방침을 통하여 고객님께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.</p>
<br/>
<p>"프레임캐치"는 개인정보취급방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.</p>
<br/>
<p>본 방침은 : 2022년 07월 24일 부터 시행됩니다.</p>
`;
