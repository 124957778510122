import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {useCallback} from "react";
import {showModalRequestAction} from "../../../redux/modal/reducer";

const Modal = () => {
    const {showModal, title, content} = useSelector((state) => state.modal);
    const dispatch = useDispatch();

    const onClose = useCallback(() => {
        dispatch(showModalRequestAction({showModal: false}));
    }, []);

    if (showModal) return (
        <Wrap>
            <ModalCloseWrap onClick={onClose}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5"
                     strokeLinecap="round" strokeLinejoin="round" className="feather feather-x">
                    <line x1="18" y1="6" x2="6" y2="18"/>
                    <line x1="6" y1="6" x2="18" y2="18"/>
                </svg>
            </ModalCloseWrap>

            <ModalTitleWrap>
                {title}
            </ModalTitleWrap>

            <ModalContentWrap dangerouslySetInnerHTML={{__html: content}}>
            </ModalContentWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
  width: 40%;
  height: 90%;
  background-color: #fff;
  position: fixed;
  top: 5%;
  left: 30%;
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 15%) 0 0 6px 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 10;

  @media screen and (max-width: 768px) {
    width: 80%;
    left: 10%;
  }
`

const ModalTitleWrap = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  justify-content: center;
`

const ModalContentWrap = styled.div`
  overflow: auto;
`

const ModalCloseWrap = styled.div`
  position: absolute;
  cursor: pointer;
  right: 20px;
`

export default Modal;
