import {useCallback, useEffect, useState} from "react";
import Label from "../../atoms/label";
import Input from "../../atoms/input";
import Button from "../../atoms/button";
import styled from "styled-components";
import {insertQuestionRequestAction, transQuestionRequestAction} from "../../../redux/question/reducer";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {loadWordsRandomRequestAction} from "../../../redux/word/reducer";

const QuizItem = ({rememberMode, type}) => {
    const dispatch = useDispatch();
    const me = useSelector(((state) => state.user.me), shallowEqual);
    const questionTranslate = useSelector(((state) => state.question.questionTranslate), shallowEqual);
    const transQuestionDone = useSelector(((state) => state.question.transQuestionDone), shallowEqual);
    const wordsRandom = useSelector(((state) => state.word.wordsRandom), shallowEqual);
    const [inputCount, setInputCount] = useState(1);
    const [inputData, setInputData] = useState({[0]: {question: '', questionTranslate: '', language: 'KO'}});

    useEffect(() => {
        const data = {};
        wordsRandom.forEach((i, index) => {
            data[index] = {question: i.word, questionTranslate: ''};
        });

        setInputCount(wordsRandom.length);
        setInputData(data);
    }, [wordsRandom]);

    useEffect(() => {
        const data = {};
        wordsRandom.forEach((i, index) => {
            data[index] = {question: i.word, questionTranslate: ''};
        });

        setInputCount(wordsRandom.length);
        setInputData(data);
    }, [rememberMode]);

    const onLoadWord = useCallback(() => {
        dispatch(loadWordsRandomRequestAction({type: Array.from(type).join(',')}));
    }, [type]);

    const onInputKeyDown = useCallback((e, type) => {
        if (e.shiftKey) {
            if (type === 'question') {
                if (e.key === 'ArrowRight') {
                    e.target.nextSibling.focus();
                }

                if (e.key === 'ArrowUp') {
                    e.target.parentNode.previousSibling.children[1].focus();
                }

                if (e.key === 'ArrowDown') {
                    e.target.parentNode.nextSibling.children[1].focus();
                }

                if (e.key === 'Alt') {
                    setInputCount((prevState) => prevState + 1);
                }
            } else {
                if (e.key === 'ArrowLeft') {
                    e.target.previousSibling.focus();
                }

                if (e.key === 'ArrowUp') {
                    e.target.parentNode.previousSibling.children[2].focus();
                }

                if (e.key === 'ArrowDown') {
                    e.target.parentNode.nextSibling.children[2].focus();
                }

                if (e.key === 'Alt') {
                    setInputCount((prevState) => prevState + 1);
                }
            }
        }
    }, []);

    const createInput = useCallback(() => {
        const result = [];

        for (let i = 0; i < inputCount; i++) {
            result.push(
                <QuizItemWrap key={i}>
                    <Label>{i + 1}</Label>
                    <QuizItemInputWrap>
                        <Input theme={'normal'} placeholder={'번역할 내용을 입력해주세요.'}
                               value={inputData[i]?.question || ''}
                               onKeyDown={(e) => onInputKeyDown(e)}
                               onChange={(e) => onTranslate(e, i)}
                               onInput={(e) => setInputData((prevState) => ({
                                   ...prevState,
                                   [i]: {...prevState[i], question: e.target.value}
                               }))}
                               disabled={true}
                        >
                        </Input>
                        <Label onClick={() => setInputData((prevState) => ({
                            ...prevState,
                            [i]: {...prevState[i], language: prevState[i].language === 'KO' ? 'EN' : 'KO'}
                        }))}>{inputData[i]?.language}</Label>
                    </QuizItemInputWrap>
                    <Input theme={'normal'} placeholder={'내용을 입력해주세요.'}
                           value={inputData[i]?.questionTranslate || ''}
                           onKeyDown={(e) => onInputKeyDown(e)}
                           onInput={(e) => setInputData((prevState) => ({
                               ...prevState,
                               [i]: {...prevState[i], questionTranslate: e.target.value}
                           }))}></Input>
                </QuizItemWrap>
            )
        }

        return result;
    }, [inputData, inputCount]);

    const upInputCount = useCallback(() => {
        setInputCount(inputCount + 1);
        setInputData((prevState) => ({
            ...prevState,
            [inputCount]: {question: '', questionTranslate: '', language: 'KO'}
        }));
    }, [inputCount]);

    const onSubmit = useCallback(() => {
        let stop = true;
        for (let i of Object.values(inputData)) {
            if (i.question.trim() || i.questionTranslate.trim()) stop = false;
        }

        if (!stop) {
            dispatch(insertQuestionRequestAction({inputData, seq: me?.seq || -1}));
            onLoadWord();
            alert('저장이 완료됐습니다.');
        } else {
            alert('빈칸을 채워주세요.');
        }
    }, [inputData]);

    const onTranslate = useCallback((e, index) => {
        if (e.target.value) dispatch(transQuestionRequestAction({text: e.target.value, index, language: inputData[index]?.language}));
    }, [inputData]);

    useEffect(() => {
        if (transQuestionDone) {
            const {index, data} = questionTranslate;

            setInputData((prevState) => ({
                ...prevState,
                [index]: {...prevState[index], questionTranslate: data}
            }));
        }
    }, [transQuestionDone, questionTranslate]);

    return (
        <QuizWrap>
            {
                createInput()
            }

            <QuizItemWrap>
                {/*{*/}
                {/*    !rememberMode && <Button shape={'rectangle'} size={'small'} color={'normal'} padding={'0 12px'} onClick={upInputCount}>+</Button>*/}
                {/*}*/}
                <Button shape={'rectangle'} size={'small'} color={'normal'} padding={'0 12px'} onClick={onSubmit}>저장</Button>
            </QuizItemWrap>
        </QuizWrap>
    )
}

const QuizWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const QuizItemWrap = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  font-size: 13px;
  height: 35px;

  & > label {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    width: 35px;
    height: 35px;
    background-color: #23C462;
    color: #fff;
    border-radius: 8px;
  }

  & > input {
    flex: 1;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: auto;
  }
`

const QuizItemInputWrap = styled.div`
  flex: 1;
  display: flex;
  position: relative;
  align-items: center;

  & > label {
    user-select: none;
    cursor: pointer;
    color: #23C462;
    font-weight: 500;
    position: absolute;
    right: 10px;
  }
  
  & > input {
    padding-right: 30px;
  }
`

export default QuizItem;
