import styled from "styled-components";
import Label from "../../atoms/label";
import Button from "../../atoms/button";
import history from "../../../utils/history";
import {useCallback, useState} from "react";

const PayTemplate = () => {
    const [loading, setLoading] = useState(false);

    const onPay = useCallback(() => {
        if (loading) return false;
        setLoading(true);
        const IMP = window.IMP;
        IMP.init("imp67011510");

        const today = new Date();
        const hours = today.getHours(); // 시
        const minutes = today.getMinutes();  // 분
        const seconds = today.getSeconds();  // 초
        const milliseconds = today.getMilliseconds();
        const makeMerchantUid = hours +  minutes + seconds + milliseconds;

        IMP.request_pay({
            pg : 'kcp',
            pay_method : 'card',
            merchant_uid: `IMP${makeMerchantUid}`,
            name : '프레임 캐치',
            amount : 10000,
            buyer_email : 'Iamport@chai.finance',
            buyer_name : '아임포트 기술지원팀',
            buyer_tel : '010-1234-5678',
        }, function (rsp) {
            if (rsp.success) {
                console.log(rsp);
            } else {
                console.log(rsp);
            }

            setLoading(false);
        });
    }, [loading]);

    return (
        <PayWrap>
            <Label> 결제하기 </Label>
            <PayBannerWrap onClick={() => history.push('/pay')}>
                <svg xmlns="http://www.w3.org/2000/svg" width="41" height="42" viewBox="0 0 41 42" fill="none">
                    <path
                        d="M4.76836 30.1558C6.63012 30.1558 8.13938 28.1436 8.13938 25.6614C8.13938 23.1792 6.63012 21.167 4.76836 21.167C2.9066 21.167 1.39734 23.1792 1.39734 25.6614C1.39734 28.1436 2.9066 30.1558 4.76836 30.1558Z"
                        fill="#FFE500"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M2.79461 22.7907C2.25727 23.5071 1.90977 24.5203 1.90977 25.6612C1.90977 26.8021 2.25727 27.8153 2.79461 28.5317C3.33182 29.248 4.03487 29.6431 4.76829 29.6431C5.5017 29.6431 6.20475 29.248 6.74197 28.5317C7.2793 27.8153 7.62681 26.8021 7.62681 25.6612C7.62681 24.5203 7.2793 23.5071 6.74197 22.7907C6.20475 22.0744 5.5017 21.6793 4.76829 21.6793C4.03487 21.6793 3.33182 22.0744 2.79461 22.7907ZM1.97462 22.1757C2.65747 21.2653 3.63994 20.6543 4.76829 20.6543C5.89663 20.6543 6.8791 21.2653 7.56195 22.1757C8.24468 23.0859 8.65181 24.3199 8.65181 25.6612C8.65181 27.0025 8.24468 28.2365 7.56195 29.1468C6.8791 30.0572 5.89663 30.6681 4.76829 30.6681C3.63994 30.6681 2.65747 30.0572 1.97462 29.1468C1.29189 28.2365 0.884766 27.0025 0.884766 25.6612C0.884766 24.3199 1.29189 23.0859 1.97462 22.1757Z"
                          fill="#45413C"/>
                    <path
                        d="M36.2317 30.1558C38.0935 30.1558 39.6028 28.1436 39.6028 25.6614C39.6028 23.1792 38.0935 21.167 36.2317 21.167C34.37 21.167 32.8607 23.1792 32.8607 25.6614C32.8607 28.1436 34.37 30.1558 36.2317 30.1558Z"
                        fill="#FFE500"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M34.258 22.7907C33.7206 23.5071 33.3731 24.5203 33.3731 25.6612C33.3731 26.8021 33.7206 27.8153 34.258 28.5317C34.7952 29.248 35.4983 29.6431 36.2317 29.6431C36.9651 29.6431 37.6681 29.248 38.2053 28.5317C38.7427 27.8153 39.0902 26.8021 39.0902 25.6612C39.0902 24.5203 38.7427 23.5071 38.2053 22.7907C37.6681 22.0744 36.9651 21.6793 36.2317 21.6793C35.4982 21.6793 34.7952 22.0744 34.258 22.7907ZM33.438 22.1757C34.1209 21.2653 35.1033 20.6543 36.2317 20.6543C37.36 20.6543 38.3425 21.2653 39.0253 22.1757C39.7081 23.0859 40.1152 24.3199 40.1152 25.6612C40.1152 27.0025 39.7081 28.2365 39.0253 29.1468C38.3425 30.0572 37.36 30.6681 36.2317 30.6681C35.1033 30.6681 34.1209 30.0572 33.438 29.1468C32.7553 28.2365 32.3481 27.0025 32.3481 25.6612C32.3481 24.3199 32.7553 23.0859 33.438 22.1757Z"
                          fill="#45413C"/>
                    <path
                        d="M28.3654 9.92969H12.6346C8.29016 9.92969 4.76831 13.4515 4.76831 17.7959V32.4034C4.76831 36.7478 8.29016 40.2697 12.6346 40.2697H28.3654C32.7099 40.2697 36.2317 36.7478 36.2317 32.4034V17.7959C36.2317 13.4515 32.7099 9.92969 28.3654 9.92969Z"
                        fill="#FF8A14"/>
                    <path
                        d="M28.3663 9.92969H12.6338C11.6008 9.92969 10.5779 10.1332 9.62359 10.5285C8.66926 10.9238 7.80214 11.5033 7.07175 12.2337C6.34137 12.9642 5.76202 13.8313 5.3668 14.7857C4.97157 15.7401 4.7682 16.763 4.76831 17.7959V21.1637C4.7682 20.1307 4.97157 19.1078 5.3668 18.1535C5.76202 17.1991 6.34137 16.3319 7.07175 15.6015C7.80214 14.871 8.66926 14.2916 9.62359 13.8962C10.5779 13.5009 11.6008 13.2974 12.6338 13.2974H28.3663C29.3992 13.2974 30.4221 13.5009 31.3764 13.8962C32.3308 14.2916 33.1979 14.871 33.9283 15.6015C34.6587 16.3319 35.238 17.1991 35.6332 18.1535C36.0285 19.1078 36.2318 20.1307 36.2317 21.1637V17.7959C36.2318 16.763 36.0285 15.7401 35.6332 14.7857C35.238 13.8313 34.6587 12.9642 33.9283 12.2337C33.1979 11.5033 32.3308 10.9238 31.3764 10.5285C30.4221 10.1332 29.3992 9.92969 28.3663 9.92969Z"
                        fill="#FFAA54"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M12.6346 10.443C8.57325 10.443 5.28086 13.7354 5.28086 17.7967V32.4042C5.28086 36.4656 8.57325 39.758 12.6346 39.758H28.3655C32.4269 39.758 35.7193 36.4656 35.7193 32.4042V17.7967C35.7193 13.7354 32.4269 10.443 28.3655 10.443H12.6346ZM4.25586 17.7967C4.25586 13.1693 8.00716 9.41797 12.6346 9.41797H28.3655C32.993 9.41797 36.7443 13.1693 36.7443 17.7967V32.4042C36.7443 37.0317 32.993 40.783 28.3655 40.783H12.6346C8.00716 40.783 4.25586 37.0317 4.25586 32.4042V17.7967Z"
                          fill="#45413C"/>
                    <path d="M13.7579 9.92969H27.242V40.2697H13.7579V9.92969Z" fill="#6DD627"/>
                    <path d="M13.7579 9.92969H27.242V13.3007H13.7579V9.92969Z" fill="#9CEB60"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M13.2455 9.93047C13.2455 9.64742 13.4749 9.41797 13.758 9.41797H27.2421C27.5251 9.41797 27.7546 9.64742 27.7546 9.93047V40.2705C27.7546 40.5535 27.5251 40.783 27.2421 40.783H13.758C13.4749 40.783 13.2455 40.5535 13.2455 40.2705V9.93047ZM14.2705 10.443V39.758H26.7296V10.443H14.2705Z"
                          fill="#45413C"/>
                    <path
                        d="M20.5 36.3369C26.7062 36.3369 31.7373 31.3058 31.7373 25.0996C31.7373 18.8934 26.7062 13.8623 20.5 13.8623C14.2938 13.8623 9.2627 18.8934 9.2627 25.0996C9.2627 31.3058 14.2938 36.3369 20.5 36.3369Z"
                        fill="#D9FDFF"/>
                    <path
                        d="M20.5 17.2333C23.1862 17.2357 25.7826 18.2007 27.8185 19.9531C29.8544 21.7056 31.1949 24.1295 31.5971 26.7855C31.8497 25.1821 31.7519 23.543 31.3104 21.981C30.8689 20.4191 30.0942 18.9713 29.0395 17.7375C27.9849 16.5037 26.6754 15.513 25.2012 14.8337C23.727 14.1545 22.1232 13.8027 20.5 13.8027C18.8768 13.8027 17.273 14.1545 15.7988 14.8337C14.3246 15.513 13.0151 16.5037 11.9604 17.7375C10.9058 18.9713 10.1311 20.4191 9.68956 21.981C9.24805 23.543 9.15027 25.1821 9.40293 26.7855C9.80508 24.1295 11.1456 21.7056 13.1815 19.9531C15.2173 18.2007 17.8138 17.2357 20.5 17.2333Z"
                        fill="white"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M20.5 14.3756C14.5769 14.3756 9.77524 19.1772 9.77524 25.1004C9.77524 31.0235 14.5769 35.8251 20.5 35.8251C26.4232 35.8251 31.2248 31.0235 31.2248 25.1004C31.2248 19.1772 26.4232 14.3756 20.5 14.3756ZM8.75024 25.1004C8.75024 18.6111 14.0108 13.3506 20.5 13.3506C26.9892 13.3506 32.2498 18.6111 32.2498 25.1004C32.2498 31.5896 26.9892 36.8501 20.5 36.8501C14.0108 36.8501 8.75024 31.5896 8.75024 25.1004Z"
                          fill="#45413C"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M23.9817 3.32714C24.1472 3.55677 24.0952 3.87707 23.8656 4.04257C22.942 4.70825 22.1978 5.5926 21.6998 6.61643C21.2018 7.64026 20.9655 8.77162 21.012 9.90919C21.0236 10.192 20.8037 10.4306 20.5209 10.4422C20.2381 10.4538 19.9995 10.2339 19.9879 9.95108C19.9344 8.64414 20.2059 7.34435 20.7781 6.16809C21.3502 4.99183 22.2051 3.97581 23.2663 3.21103C23.4959 3.04553 23.8162 3.09752 23.9817 3.32714ZM17.3791 24.9034C17.6621 24.8976 17.8962 25.1223 17.9021 25.4053C17.9087 25.7239 17.9814 26.0377 18.1156 26.3268C18.2498 26.6159 18.4425 26.874 18.6817 27.0847C18.9208 27.2954 19.2011 27.4542 19.5048 27.5509C19.8085 27.6476 20.129 27.6802 20.4459 27.6466C20.4819 27.6428 20.5181 27.6428 20.554 27.6466C20.8709 27.6802 21.1914 27.6476 21.4951 27.5509C21.7988 27.4542 22.0791 27.2954 22.3182 27.0847C22.5574 26.874 22.7502 26.6159 22.8844 26.3268C23.0186 26.0377 23.0912 25.7239 23.0978 25.4053C23.1037 25.1223 23.3378 24.8976 23.6208 24.9034C23.9038 24.9093 24.1285 25.1434 24.1226 25.4264C24.1131 25.887 24.008 26.3406 23.8141 26.7584C23.6201 27.1762 23.3415 27.5492 22.9959 27.8538C22.6502 28.1583 22.2451 28.3878 21.8062 28.5276C21.3845 28.6619 20.9404 28.7106 20.5 28.6712C20.0595 28.7106 19.6154 28.6619 19.1938 28.5276C18.7548 28.3878 18.3497 28.1583 18.0041 27.8538C17.6584 27.5492 17.3798 27.1762 17.1858 26.7584C16.9919 26.3406 16.8868 25.887 16.8773 25.4264C16.8715 25.1434 17.0961 24.9093 17.3791 24.9034Z"
                          fill="#45413C"/>
                    <path
                        d="M24.6 4.19047C25.2793 4.19047 25.83 3.63978 25.83 2.96047C25.83 2.28116 25.2793 1.73047 24.6 1.73047C23.9207 1.73047 23.37 2.28116 23.37 2.96047C23.37 3.63978 23.9207 4.19047 24.6 4.19047Z"
                        fill="#FFE500"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M24.6 2.24277C24.2038 2.24277 23.8825 2.56401 23.8825 2.96027C23.8825 3.35654 24.2038 3.67777 24.6 3.67777C24.9963 3.67777 25.3175 3.35654 25.3175 2.96027C25.3175 2.56401 24.9963 2.24277 24.6 2.24277ZM22.8575 2.96027C22.8575 1.99792 23.6377 1.21777 24.6 1.21777C25.5624 1.21777 26.3425 1.99792 26.3425 2.96027C26.3425 3.92263 25.5624 4.70277 24.6 4.70277C23.6377 4.70277 22.8575 3.92263 22.8575 2.96027Z"
                          fill="#45413C"/>
                    <path
                        d="M25.83 23.8702C26.5093 23.8702 27.06 23.3195 27.06 22.6402C27.06 21.9608 26.5093 21.4102 25.83 21.4102C25.1507 21.4102 24.6 21.9608 24.6 22.6402C24.6 23.3195 25.1507 23.8702 25.83 23.8702Z"
                        fill="#45413C"/>
                    <path
                        d="M15.1699 23.8702C15.8493 23.8702 16.3999 23.3195 16.3999 22.6402C16.3999 21.9608 15.8493 21.4102 15.1699 21.4102C14.4906 21.4102 13.9399 21.9608 13.9399 22.6402C13.9399 23.3195 14.4906 23.8702 15.1699 23.8702Z"
                        fill="#45413C"/>
                </svg>

                <PayBannerTextWrap>
                    <Label>프레임캐치만의 방식으로 학습해보세요,</Label>
                    <Label>누구나 쉽게 배울 수 있어요!</Label>
                </PayBannerTextWrap>
            </PayBannerWrap>

            <PayDangerWrap>
                <Label>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#23C462" stroke="#fff" strokeWidth="2"
                         strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="12" r="10"></circle>
                        <line x1="12" y1="8" x2="12" y2="12"></line>
                        <line x1="12" y1="16" x2="12.01" y2="16"></line>
                    </svg>

                    취소 및 환불 안내
                </Label>
                <Label>
                    결제일로부터 3일이 경과한 경우 취소 및 환불이 불가합니다.
                </Label>
            </PayDangerWrap>

            <PayCostWrap>
                <Label>결제 금액 (1개월) </Label>
                <Label><strong>10,000 </strong>원</Label>
            </PayCostWrap>

            <Button shape={'rectangle'} size={'middle'} color={'normal'} onClick={onPay} loading={loading}>결제하기</Button>
        </PayWrap>
    )
};

const PayWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 400px;
  margin: 0 auto;
  padding: 50px 20px;

  & > label {
    font-size: 36px;
    font-weight: 600;
    text-align: center;
    padding-bottom: 20px;
    margin-bottom: 10px;
  }
`

const PayBannerWrap = styled.div`
  display: flex;
  gap: 20px;
  background-color: #FAE193;
  padding: 20px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const PayBannerTextWrap = styled.div`
  display: flex;
  flex-direction: column;
`

const PayDangerWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  & label:first-child {
    font-size: 16px;
    font-weight: bold;
    color: #23C462;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  & label:last-child {
    color: #61616d;
  }
`

const PayCostWrap = styled.div`
  display: flex;
  justify-content: space-between;
  
  & label {
    font-size: 16px;
  }
  
  & strong {
    font-size: 16px;
    color: #23C462;
  }
`

export default PayTemplate;
