import LayoutTemplate from "../../components/templates/layout";
import BoardAddTemplate from "../../components/templates/board/add";

const BoardAdd = () => {
    return (
        <LayoutTemplate>
            <BoardAddTemplate/>
        </LayoutTemplate>
    )
}

export default BoardAdd;