import styled from "styled-components";
import Button from "../../atoms/button";
import useDeviceDetect from "../../../hooks/useDeviceDetect";
import {shallowEqual, useSelector} from "react-redux";
import UserItem from "../../oraganisms/home/user";
import RankItem from "../../oraganisms/home/rank";
import QuizItem from "../../oraganisms/home/quiz";
import QuizNonMemberItem from "../../oraganisms/home/quizNonMember";
import {useCallback, useEffect, useState} from "react";
import WordItem from "../../oraganisms/home/word";

const HomeTemplate = () => {
    const me = useSelector(((state) => state.user.me), shallowEqual);
    const type = useSelector(((state) => state.type.types), shallowEqual);
    const {isMobile} = useDeviceDetect();
    const [rememberMode, setRememberMode] = useState(false);
    const [selectedType, setSelectedType] = useState(0);
    const [selectedTypeAll, setSelectedTypeAll] = useState(new Set([0]));

    const onClickType = useCallback((index) => {
        setSelectedType(index);

        setSelectedTypeAll((prevState) => {
            prevState.clear();
            prevState.add(0);
            return new Set([...prevState]);
        });
    }, [selectedTypeAll]);

    const onClickTypeSub = useCallback((index) => {
        setSelectedTypeAll((prevState) => {
            if (selectedTypeAll.has(index)) {
                if (prevState.size !== 1) {
                    prevState.delete(index);
                    return new Set([...prevState]);
                } else {
                    return selectedTypeAll;
                }
            } else {
                prevState.add(index);
                return new Set([...prevState]);
            }
        });
    }, [selectedTypeAll]);

    useEffect(() => {
        if (type.length > 0) {
            setSelectedType(type[0].seq);

            if (type[0].sub.length > 0) setSelectedTypeAll(new Set([type[0].sub[0].seq]));
        }
    }, [type]);

    return (
        <HomeWrap>
            {
                !isMobile &&
                <HomeLeftWrap>
                    <UserItem />
                    <WordItem type={selectedTypeAll} rememberMode={rememberMode} setRememberMode={setRememberMode}/>
                </HomeLeftWrap>
            }

            <HomeCenterWrap>
                <HomeCenterHeaderWrap>
                    {
                        type.map((i, index) => <Button key={index} className={selectedType === i.seq ? 'active' : ''}
                                                       onClick={() => onClickType(i.seq)}>{i.name}</Button>)
                    }
                </HomeCenterHeaderWrap>

                <HomeCenterHeaderSubWrap>
                    {
                        type.find(i => i.seq === selectedType)?.sub.map((i, index) => <Button key={index}
                                                                                              className={selectedTypeAll.has(i.seq) ? 'active' : ''}
                                                                                              onClick={() => onClickTypeSub(i.seq)}>{i.name}</Button>)
                    }
                </HomeCenterHeaderSubWrap>

                {
                    me.seq ? <QuizItem rememberMode={rememberMode} type={selectedTypeAll}/> : <QuizNonMemberItem rememberMode={rememberMode} type={selectedTypeAll}/>
                }

                {
                    isMobile && <WordItem type={selectedTypeAll} rememberMode={rememberMode} setRememberMode={setRememberMode}/>
                }
            </HomeCenterWrap>

            <HomeRightWrap>
                <RankItem />
            </HomeRightWrap>
        </HomeWrap>
    );
};

const HomeWrap = styled.div`
  display: flex;
  gap: 20px;
  max-width: 1440px;
  margin: 0 auto;
  padding: 50px 20px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 40px 15px;
  }
`

const HomeLeftWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;

  @media screen and (max-width: 768px) {
    order: 0;
  }
`

const HomeCenterWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 60%;

  @media screen and (max-width: 768px) {
    order: 0;
    width: 100%;
  }

  @media screen and (min-width: 769px) and (max-width: 899px) {
    width: 30%;
  }

  @media screen and (min-width: 900px) and (max-width: 1099px) {
    width: 40%;
  }

  @media screen and (min-width: 1100px) and (max-width: 1399px) {
    width: 50%;
  }
`

const HomeCenterHeaderSubWrap = styled.div`
  display: flex;
  gap: 20px;
  overflow-x: auto;
  white-space: nowrap;
  border-top: 1px solid #eee;
  padding-top: 20px;

  &::-webkit-scrollbar {
    display: none;
  }

  & > button {
    font-size: 18px;
    background-color: #fff;
    color: #6FCF97;
    padding: 10px 12px;
    border: 1px solid #6FCF97;
    border-radius: 8px;
  }

  & > button.active {
    background-color: #23C462;
    border: 1px solid #23C462;
    color: #fff;
  }

  @media screen and (max-width: 768px) {
    gap: 10px;
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(3, calc((100% / 3) - 10px));

    & > button {
      font-size: 16px;
      padding: 5px 6px;
    }
  }
`

const HomeCenterHeaderWrap = styled.div`
  display: flex;
  gap: 20px;
  overflow-x: auto;
  white-space: nowrap;

  &::-webkit-scrollbar {
    display: none;
  }

  & > button {
    font-size: 18px;
    background-color: #fff;
    color: #6FCF97;
    padding: 10px 12px;
    border: 1px solid #6FCF97;
    border-radius: 8px;
  }

  & > button.active {
    background-color: #23C462;
    border: 1px solid #23C462;
    color: #fff;
  }

  @media screen and (max-width: 768px) {
    gap: 10px;
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(3, calc((100% / 3) - 10px));

    & > button {
      font-size: 16px;
      padding: 5px 6px;
    }
  }
`

const HomeRightWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;

  @media screen and (max-width: 768px) {
    order: 2;
  }
`

export default HomeTemplate;
