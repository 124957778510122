import styled from "styled-components";
import Label from "../../atoms/label";
import Input from "../../atoms/input";
import Button from "../../atoms/button";
import useInput from "../../../hooks/useInput";
import {useCallback} from "react";
import {loginRequestAction} from "../../../redux/user/reducer";
import {useDispatch} from "react-redux";
import MixCheckbox from "../../molecules/mixCheckbox";
import localStorage from "../../../utils/common/localStorage";

const LoginTemplate = () => {
    const dispatch = useDispatch();
    const [id, onChangeId, setId] = useInput(localStorage.getItem('framecatch_id_store') || '');
    const [password, onChangePassword, setPassword] = useInput('');
    const [isSaveId, onChangeIsSaveId, setIsSaveId] = useInput(0);

    const onLogin = useCallback(() => {
        if (isSaveId) localStorage.setItem('framecatch_id_store', id);
        dispatch(loginRequestAction({id, password}));
    }, [id, password, isSaveId]);

    return (
        <LoginWrap>
            <Label> 로그인 </Label>
            <Input type={"text"} theme={'normal'} size={'sm'} placeholder={'아이디'} value={id} onChange={onChangeId}/>
            <Input type={"password"} theme={'normal'} size={'sm'} placeholder={'비밀번호'} value={password} onChange={onChangePassword}/>
            <MixCheckbox theme={'checkbox'} id={'checkbox'} value={isSaveId} onChange={onChangeIsSaveId}>아이디 저장</MixCheckbox>
            <Button shape={'rectangle'} size={'middle'} color={'normal'} onClick={onLogin}>로그인</Button>
        </LoginWrap>
    )
};

const LoginWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 400px;
  margin: 0 auto;
  padding: 50px 20px;
  
  & > label {
    font-size: 36px;
    font-weight: 600;
    text-align: center;
    border-bottom: 4px solid #23C462;
    padding-bottom: 20px;
    margin-bottom: 10px;
  }
  
  & > button {
    margin-top: 10px;
  }
`

export default LoginTemplate;
